const translations = {
  en: {
    translation: {
      "Home-Title": "Check car before deal",
      "Home-Subtitle": "Accidents, lien, owners",
      "Home-Sale": "Sale price only - 79.90 NIS",
      "Home-Car number placeholder": "Enter car number",
      "Home-Check button": "Check",
      "Checking the seller vehicle": "Checking vehicle owners",
      "Official mortgage check": "Official mortgage check",
      "Vehicle history from database": "Vehicle history from database",
      "Impairment test and total loss": "Impairment test and total loss",
      "Maintenance costs and red flags": "Maintenance costs and red flags",
      "Start with": "Start with",
      "Start with info": "Reverse takes you back to the car's past, collects information for you and gives you the car's history just in time. The right one. Buying a second-hand car. Was it a total loss? Maybe the car before was stolen or repossessed? What is his financial history? There may be something in his past that they are trying to hide. Everything you need to know - in 5 minutes, straight to your email",
      "Check before seen": "Check the car, before you saw it",
      "Check before seen info": "Our systems collect details from all databases: the official ones, all you have to do is click and receive within minutes the result exactly as soon as it is relevant to the purchase",
      "Vehicle CV": "Vehicle CV - what does it mean?",
      "Vehicle CV info": "The simplest there is: a simple, legible and clear document that comes directly to your email and lists the various data that the authorities know about the used vehicle you are considering buying: accidents, liens, mortgages, depreciation, cost history. Insurance claims - everything",
      "Check list": "What are we actually checking here?",
      "Check list info": "Reverses the previous costs | Reverse for pawnshops | Reverse to the history of the car | Reverse for impairment and total loss | Reverse Maintenance Warnings and Recalls",
      "Guide": "Guiding video",
      "How report looks": "Want to see what a check car report looks like",
      "Full name": "Full name",
      "Full name require": "Please input your full name!",
      "Phone number": "Phone number",
      "Phone number require": "Please input your phone number!",
      "Email address": "Email address",
      "Email address require": "Please input your email!",
      "Send": "Send",
      "The commandments": "The commandments",
      "Report of stolen vehicle": "Report of stolen vehicle",
      "Report of a car seller": "Report of a car seller",
      "Accessibility": "Accessibility",
      "Contact us": "Contact us in reverse!",
      "To cancel an order": "To cancel an order",
      "Terms": "Terms",
      "Privacy Policy": "Privacy Policy",
      "Drawer title": "Settings",
      "User logged out": "User logged out",
      "Log In": "Log In",
      "Logged as": "Logged as",
      "Logout": "Logout",
      "Login account": "Login account",
      "LOGIN": "LOGIN",
      "OR REGISTER": "OR REGISTER",
      "Something went wrong": "Something went wrong",
      "User logged in": "User logged in",
      "Email address is not valid": "Email address is not valid",
      "Password require": "Please input your password!",
      "Password": "Password",
      "Reset password": "Reset password",
      "Remember me": "Remember me",
      "Confirm password require": "Please input your confirm password!",
      "Password conditions": "Min 8 chars, at least 1 letter and 1 number",
      "Confirm password": "Confirm password",
      "Passwords do not match": "Passwords do not match",
      "SIGNUP": "SIGNUP",
      "RESET PASSWORD": "RESET PASSWORD",
      "BACK": "BACK",
      "Reset password - Alert description": "A request to reset your password has been sent to your email address. Please check your inbox to continue the process.",
      "Show report": "Show report",
      "Show reports history": "Show reports history",
      "Created date": "Created date",
      "File": "File",
      "Main page": "Main page",
      "Email sent successfully": "Email sent successfully",
      "Email sent description": "We have received your inquiry and we will contact you soon",
      "Owner ID": "ID number of the main vehicle owner",
      "Owner date": "Date of commencement of ownership of the vehicle (according to the vehicle license)",
      "Report preparation alert": "Thanks, your report is in preparation and may take several minutes. When done, you can view it on your reports page.",
      "Search another car": "Search another car",
      "Report demo": "Report demo",
      "Order report": "Order report",
      'Why-menu': 'למה?',
      'Why-title': 'למה חייבים להיכנס לרוורס?',
      'Why-subtitle': 'המידע שרוורס מעניקה לכם יכול לחסוך קניה מיותרת של רכב פגום. מכאנית או משפטית רוצים לנסוע בראש שקט? כנסו לרוורס',
      'Why-label-1': 'בדיקת רכב מעוקל',
      'Why-info-1': 'בעל חוב אשר עושה כל שניתן כדי לקבל את כספו פותח תיק בהוצאה לפועל ועקב כך נרשם עיקול במשרד התחבורה על הרכב בעל מספר הרישוי הספציפי. מי שמעוניין לבצע העברת בעלות ממוחשבת באחד מסניפי רשות הדואר, אך מסתבר, שהרכב מעוקל, לא יוכל לבצע העברת בעלות',
      'Why-label-2': 'איך פותרים את הבעיה?',
      'Why-info-2': 'אם הרוכש מעוניין ברכב זה, קיימות שתי אופציות לפתרון וזאת רק אם הבעלים הנוכחיים של הרכב, מכיר בחוב ומבין שיש לשלם אותו',
      'Why-info-2-checkmark-1': 'הקונה ישלם את החוב וכך יוסר השעבוד מהרכב. בשלב שני הוא ישלם למוכר הרכב רק את היתרה שנותרה ממחיר הרכב שעליו סוכם בקיזוז ערך החוב ששולם.',
      'Why-info-2-checkmark-2': 'אם המוכר ישלם בעצמו את החוב ורק לאחר שיוכיח שהשעבוד בוטל, אפשר יהיה לבצע העברת בעלות ממוחשבת.',
      'Why-label-3': 'רכב משועבד',
      'Why-info-3': 'בעל רכב שקיבל מימון לצורך רכישת הרכב מפעיל שעבוד על הרכב, כיוון שהרכב משמש בטוחה על החזר כל כספי ההלוואה. לפני שקונים, יש לוודא שאין שיעבוד על הרכב ואת זה. בדיווק רוורס עושה עבורכם',
      'Why-label-4': 'רכב ממושכן ברשם המשכונות',
      'Why-info-4': 'דרך נוספת להעביר בעלות,היא רישום של פרטי רכב ספציפי ברשם המשכונות וכך למנוע את. מכירתו לאדם אחר',
      'Why-label-5': 'הדרך הפשוטה להימנע מסיבוכים',
      'Why-info-5': 'בדיקה קטנה יכולה לחסוך לכם כאב ראש גדול נכנסים לרוורס ומגלים את כל מה שלא סיפרו. לכם על הרכב שאתם עומדים לקנות',
      'Handicapped-menu': 'תו נכה',
      'Handicapped-title': 'תו נכה: לשים לב לפני שקונים',
      'Handicapped-subtitle': 'אתם הרי ממש לא רוצים להגיע למצב שאין באפשרותכם להעביר בעלות, נכון? אז כדי למנוע, סיבוכים מיותרים, יש לדאוג לבטל את תו הנכה(במידה וקיים). בטרם מכירת הרכב הנה כל מה שצריך לדעת כדי לעשות את זה נכון',
      'Handicapped-label-1': 'מה הוא תו נכה?',
      'Handicapped-info-1': 'תו נכה הוא רישיון לחנות במקומות חניה השמוריםהלנכים בלבד – מוכר על ידי הגורמים הרשמיים של. מדינת ישראל בעל רכב שעמד בקריטריונים וקיבל ממשרד הרישוי, תעודה של נכה אשר מקלה על חיי היום יום שלו מקבל תג נכה שמודבק על שמשת הרכב שלו והדבר: מופיע ברשומות של משרד הרישוי. נכים וחולים',
      'Handicapped-label-2': 'קונים רכב? לא לשכוח לבדוק את נושא תו הנכה',
      'Handicapped-info-2': 'אחד הדברים שצריך לבדוק כל מי שרוכש רכב יד שנייה הוא אם לרכב זה משוויך תג נכה אשר מאפשר לו לחנות במקומות חנייה מיוחדים היות ומי שרוכב רכב יד שנייה וימשיך להחזיק (אפילו לא ביודעין)',
      'Handicapped-label-3': 'אז מה עושים במשרד הרישוי?',
      'Handicapped-info-3': 'לאור ההטבות וההקלות בנושא חנייה עבור הנכים כאשר מוכרים רכב הנושא תו, משרד התחבורה לא מאפשר להעביר זכות זו של תג נכה לבעלים החדשים של הרכב כיוון שהם אינם מוכרים כנכים על ידי משרד הרישוי לכן, לפני ביצוע העברת הבעלות על בעל הרכב הנוכחי להחזיר למשרד הרישוי את תג הנכה, כדי שהסטטוס של מספר רישוי זה יהיה כמו כל רכב נורמטיבי ולא בעל זכאות ייחודית. רק לאחר. שמבצעים מהלך זה אפשר לבצע העברת בעלות',
      'Handicapped-label-4': 'קונים רכב? תבדקו מראש את נושא התו נכה',
      'Handicapped-info-4': 'מי שקונה רכב ואינו יודע שיש לו תו נכה, כשירצה לבצע העברת בעלות הוא ייתקל בסירוב, זאת, בשל תג הנכה. במקרים האלו הוא יבקש מבעל הרכב לבצע את המהלך הנדרש ורק לאחר מכן יוכל. לבצע את השינוי הנדרש',
      'Curved vehicle-menu': 'רכב מעוקל או משועבד',
      'Curved vehicle-title': 'רכב מעוקל או משועבד איך נמנעים מקנייה?',
      'Curved vehicle-subtitle': 'מה עושים כדי לא להגיע למצב שאתם עומדים לקנות רכב ואז מגלים שבעצם הבעלים הם לא לגמרי הבעלים? ניחשתם נכון: נכנסים לרוורס',
      'Curved vehicle-label-1': 'הצד המשפטי של הרכב הבא שלכם',
      'Curved vehicle-info-1': 'אדם שמעוניין לרכוש רכב יד שנייה, חייב לבדוק את מצבו של הרכב מבחינה מכנית, חשמלית וגם את הפאן המשפטי. יש מקרים שבהם הרכב מעוקל או משועבד ולכן יש להיות ערניים כדי לא להיקלע לצרה.',
      'Curved vehicle-label-2': 'רוורס פותרת את הבעיה',
      'Curved vehicle-info-2': 'המידע שמקבלים מקורות החיים של הרכב מרוורס מאפשר לדעת האם הרכב מעוקל או משועבד וכמו כן הרבה דברים אחרים שחשוב לבדוק ולדעת.',
      'Curved vehicle-label-3': 'העברת בעלות של הרכב',
      'Curved vehicle-info-3': 'מצאת רכב שנראה לך מתאים לדרישותיך? מצויין. עכשיו, כדאי לבדוק שגם המצב המשפטי שלו ראוי לעסקה',
      'Curved vehicle-label-4': 'שעבוד של רכב',
      'Curved vehicle-info-4': 'אדם שחייב כספים לגורמים שונים כמו ספקים, רשויות המס, או כל גורם אחר וקיים חשש שהוא לא יעמוד בהתחייבויות הכספיות שלו, בעל החוב פותח לו תיק בהוצאה לפועל. בדרך זו הוא מעקל כספים, נכסים ורכבים אשר קיימים ברשותו של אדם זה. המטרה היא לממש את הנכסים הללו, או חלק מהם כדי לקבל בדרך זו את התשלום אשר מגיע לגורם זה.',
      'Curved vehicle-label-5': 'משכון של רכב',
      'Curved vehicle-info-5': 'יש גורמים שמבצעים משכון לרכב כאן ברוורס, ניתן לדעת אם יש משכון ולמי',
      'Recall-menu': 'צו ריקול',
      'Recall-title': 'צו ריקול? יש חלק פסול!',
      'Recall-subtitle': 'ריקול הוא מצב שבו יצרנית רכב מכריזה על כך שיש חלק עם פגם בייצור ויש להחליפו בכל הרכבים. כשקונים רכב משומש יש לשים לב שהרכב שאתם קונים ביצע ריקול במידה והיה צריך',
      'Recall-label-1': 'כשיצרנית מלה פגם בייצור והרכבים כבר על הכביש',
      'Recall-info-1': 'יצרני הרכב בכל העולם מקדישים מחשבה רבה לכל נושא הבטיחות ברכב ולכן הם בוחנים את כל תהליכי התיקון והבדיקה, אשר קור מהרגע שהרכב נמכר וזאת כיוון שהרכבים מגיעים לטיפולים ולבדיקות שוטפות במוסכים בהתאם להוראות של יצרן הרכב.',
      'Recall-label-2': 'קריאת ריקול לרכבים',
      'Recall-info-2': 'אם קיימת תקלה שהיא חד פעמית או תקלה שחוזרת על עצמה שוב ושוב, מהנדסי הרכב בודקים זאת באופן מעמיק ולא אחת הם מגיעים למסקנה שיש בעיה טכנית בחלקים מסוימים ולכן יש להחליפם כדי להגן על הנהגים והנוסעים ולהעניק להם בטיחות מלאה, יצרני הרכב מייצרים את החלף החדש שיהיה מתאים יותר מהחלק המקורי. השלב הבא הוא קריאה לכל בעלי הרכב בדגם הספציפי על פי שנת הייצור להגיע לאחד המוסמכים המורשים כדי לבצע את ההחלפה. כל מי שרכש רכב מסוג זה, מקבל על כך הודעה באחד מאמצעי התקשורת ובהתאם לכך הוא מתאם תור למוסך מורשה כדי לבצע את ההחלפה בפועל.',
      'Recall-label-3': 'מחויבות לביצוע ריקול בפועל',
      'Recall-info-3': 'לאור החשיבות הרבה של ביצוע הריקול, כדי למנוע מצבים שהם לא בטיחותיים, כל בעל רכב מחויב לבצע פעולה זו מוקדם ככל האפשר',
      'Recall-label-4': 'ריקול, תנאי הכרחי כדי לעבור את הטסט',
      'Recall-info-4': 'בכל שנה כל רכב עובר מבחן רישוי שנתי כדי לבדוק את תקינותו. בעל רכב שמגיע למכון הרישוי ועל פי הבדיקה יסתבר שהוא לא פעל כנדרש ולא בוצעה החלפה של החלק הספציפי על פי הוראת היצרן, לא יוכל לעבור את מבחן הטסט השנתי רק לאחר שהוא יבצע את הפעולה הנדרשת, הוא יגיע שוב למכון הרישוי ואם הכול תקין, הוא יעבור את הטסט ויוכל להמשיך לנסוע ברכב',
      'Certified garage-menu': 'מוסך מורשה',
      'Certified garage-title': 'מוסך מורשה: מדוע הוא הכרחי?',
      'Certified garage-subtitle': 'משרד התחבורה מעניק רישיון ופיקוח בשבילנו ציבור הנהגים שרוצה לדעת שהרכב שלנו בידיים טובות',
      'Certified garage-label-1': 'מה זה מוסך?',
      'Certified garage-info-1': 'מוסכים מורשים מטעם משרד התחבורה הם מוסכים שנמצאים בפיקוח של משרד התחבורה והם בדרך כלל חברים באיגוד המוסכים',
      'Certified garage-label-2': 'מה הסטנדרט אליו מחויבים המוסכים המורשים?',
      'Certified garage-info-2': 'במוסך מורשה משרד התחבורה מעניקים טיפול מקצועי לרכבים מכל הסוגים ושל כל יצרני הרכב אשר הרכבים המיובאים ארצה. המוסכים הללו מחויבים לפעול על פי תקנות מחמירות. עובדי מוסך עוברים הכשרות והדרכות מקצועיות בכל הקשור לאופן הבדיקה, התיקון ותחזוקה של כל אחד מהרכבים של היצרנים השונים. בראש המוסך מחויב לעמוד מכונאי בעל הכשרות ספציפיות, המנהל את המערך המקצועי',
      'Certified garage-label-3': 'שקיפות: ככה עובד מוסך מורשה',
      'Certified garage-info-3': 'במוסך מסוג זה חייב להיות תלוי מחירון במקום גלוי לעין. שם מפורטות העלויות של שעת העבודה, ושל העלות של החלפים המקוריים. עובדי המוסך שמקבלים רכב לטיפול, מחויבים לאחר הבדיקה ליידע את הלקוח מהם הממצאים שלהם, מה יש לתקן ומהן העלויות של התיקון, החלפים ושעות העבודה. רק לאחר אישורו של הלקוח לביצוע העבודה, צוות העובדים מבצע זאת בפועל',
      'Certified garage-label-4': 'תיעוד של הפעולות במוסך מורשה',
      'Certified garage-info-4': 'במוסך מורשה, כל הנתונים על התיקונים השונים שבוצעו ברכב, אילו חלפים הוחלפו, מתי בוצעו הטיפולים השוטפים וכל מידע אחר, מפורטים בתוכנה הממוחשבת. כך אפשר לבדוק לדוגמה מתי הוחלפו הבלמים, רצועת הטיימינג וכו’. מידע זה חשוב לכל בעל רכב ובמיוחד בעת רכישת רכב יד שנייה וכך הוא רואה את מלוא האינפורמציה באופן מרוכז',
      'Certified garage-label-5': 'מוסכי מדרכה – איך מזהים ונזהרים?',
      'Certified garage-info-5': 'מוסכי מדרכה הם מוסכים פיראטיים אשר פועלים ללא פיקוח. לכן לא אחת הנהגים לא מרוצים מהטיפולים שבוצעו ברכב כיוון שאין למי לפנות במקרה של בעיה ואין עליהם פיקוח של משרד התחבורה. המחירים במוסכים הללו הם גבוהים ולא תמיד מותקנים ברכבים המגיעים לתיקון חלפים מקוריים או חדשים. יש חלפים משומשים שמותקנים ברכבים שהם מחזיקים מעמד פרק זמן קצר, או שהם לא תקינים לאור כל זאת ההמלצה היא לא לתקן את הרכבים במוסכי מדרכה',
      'Car dealer-menu': 'סוחר רכב מורשה',
      'Car dealer-title': 'סוחר רכב מורשה: למה הוא חשוב בעצם?',
      'Car dealer-subtitle': 'רוצים לקנות רכב בלי להסתכן בעסקה מפוקפקת? קונים מסוחר רכב מורשה המחזיק ברישיון ממשרד התחבורה ונוסעים בראש שקט',
      'Car dealer-label-1': 'מהו סוחר רכב מורשה?',
      'Car dealer-info-1': 'סוחר רכב מורשה הוא בעל מקצוע שקיבל אישור על כך שהעסק שלו בתחום המסחר במכוניות הוא מפוקח ולכן הוא מאושר לתפעול על ידי משרד התחבורה.',
      'Car dealer-label-2': 'היתרונות לקניה מסוחר רכב',
      'Car dealer-info-2-checkmark-1': 'קנייה ממקום עם אחריות',
      'Car dealer-info-2-checkmark-2': 'קנייה ממקום אשר דואג למוניטין שלו',
      'Car dealer-info-2-checkmark-3': 'קנייה מבעל מקצוע אשר אמון לדעת ולהבין את מאפייני הרכב ואחראי עליהם',
      'Car dealer-info-2-checkmark-4': 'קבלת רכב לאחר השבחה',
      'Car dealer-label-3': 'מטפלים ברכב ומוכרים אותו',
      'Car dealer-info-3': 'המגמה של כל אחד מסוחרי הרכב היא לרכוש רכבים שונים בעלות שהיא נמוכה ממחיר המחירון ולאחר מכן הם מעבירים את הרכב לטיפול של אנשי מקצוע שהם עובדים איתם בשיתוף פעולה כמו חשמלאי רכב, מכונאי רכב, איש פחחות, איש צבע ועוד בהתאם למצבו שלה הרכב הוא מזמין מהם את ביצוע הטיפולים הנדרשים כדי שאפשר יהיה למכור רכב תקין, כמו לדוגמה צבע ותיקוני פחחות. לאחר מכן כל רכב עובר ניקיון יסודי פנימי וחיצוני וכך הוא מוכן למכירה בשלב הבא, מפרסם את הרכב למכירה במדיות השונות.',
      'Car dealer-label-4': 'קנייה של רכבים משומשים',
      'Car dealer-info-4': 'סוחרי הרכב קונים רכבים במכרזים, מגרשים של רכבי טרייד-אין ומאנשים פרטיים. הסוחרים מחזיקים בתו סחר שמאפשר להם לטפל ברכב עד מכירתו ללא צורך בהעברת בעלות וכך לא נרשמת על הרכב יד נוספת. בנוסף לכך, תו זה מאפשר להקפיא את מועד הטסט על מכירת הרכב והבעלים החדשים דואג לתשלום הטסט ולהעברת הרכב במבחן השנתי לגבי תקינותו.',
      'How it works-menu': 'איך זה עובד?',
      'How it works-title': 'איך זה בעצם עובד?',
      'How it works-subtitle': 'שלושה שלבים ויש לכם קורות חיים של רכב עם נתונים ותובנות לצאת לדרך',
      'How it works-label-1': 'חלק ראשון: רוורס 7 שנים אחורה',
      'How it works-info-1': 'מציידים אתכם במידע על הנקודות הרגישות ביותר בקניית רכב משומש',
      'How it works-info-1-checkmark-1': 'מעורבות בתאונות?',
      'How it works-info-1-checkmark-2': 'ירידת ערך עקב תאונות?',
      'How it works-info-1-checkmark-3': 'האם בעבר הרכב נגנב?',
      'How it works-info-1-checkmark-4': 'האם הרכב היה מבוטח?',
      'How it works-info-1-checkmark-5': '?האם הרכב הוכרז כטוטאל לוס',
      'How it works-info-1-checkmark-6': '?האם שולמו פיצויים מחברות הביטוח',
      'How it works-label-2': 'שלב שני: בעלויות קודמות',
      'How it works-info-2': 'מי שמוכר רכב יד שלישית אולי לא יודע הכל על העבר של הרכ',
      'How it works-info-2-checkmark-1': '?האם הרכב היה בבעלות גורם מסחרי בעבר',
      'How it works-info-2-checkmark-2': '?אולי יש סיבה לחשוד באחת הבעלויות הקודמות',
      'How it works-label-3': 'שלב שלישי: מסקנות וכלים לעסקה נכונה',
      'How it works-info-3': 'מציידים אתכם במידע על הנקודות הרגישות ביותר בקניית רכב משומש',
      'How it works-info-3-checkmark-1': 'האם ישנם נתונים בעייתיים שכדאי לשים לב אליהם?',
      'How it works-info-3-checkmark-2': 'האם דווקא הרכב הזה עושה רושם טוב?',
      'How it works-info-3-checkmark-3': '?מה הבינה המלאכותית שלנו אומרת על ההזדמנות',
      'How it works-info-4': 'מערכת הבינה המלאכותית מסייעת לכם עם תובנות, סיכומים ומסקנות, בעצם חושבת ביחד אתכם: מה הצעד שהכי נכון לעשות עכשיו',
      'How it works-info-4-checkmark-1': '?בכמה כדאי לקנות',
      'How it works-info-4-checkmark-2': '?ירידת ערך – מה אומרת התחזית שלנו',
      'How it works-info-4-checkmark-3': '?כמה יעלה לתחזק את הרכב הזה',
      'How it works-info-4-checkmark-4': 'טפסי מכירה',
      'How it works-info-4-checkmark-5': '?הנחיות וטיפים: כיצד יש לנהוג במכירה או קנייה',
      'Total loss-menu': 'טוטאל לוס',
      'Total loss-title': 'טוטאל לוס? איך לא קונים רכב כזה',
      'Total loss-subtitle': 'טוטאל לוס הוא מצב שבו רכב מורד מהכביש לאחר שהנזק שנגרם מתאונה שווה ל60% ומעלה מערכו. מה התהליך שמאחורי הכרזה על רכב כזה? כל המידע לפניכם',
      'Total loss-label-1': 'שמאי רכב – איש המקצוע בדיוק למצבים האלה',
      'Total loss-info-1': 'תו נכה הוא רישיון לחנות במקומות חניה השמוריםהלנכים בלבד – מוכר על ידי הגורמים הרשמיים של. מדינת ישראל בעל רכב שעמד בקריטריונים וקיבל ממשרד הרישוי, תעודה של נכה אשר מקלה על חיי היום יום שלו מקבל תג נכה שמודבק על שמשת הרכב שלו והדבר: מופיע ברשומות של משרד הרישוי. נכים וחולים',
      'Total loss-label-2': 'טוטאל לוס – מה זה?',
      'Total loss-info-2': 'טוטאל לוס הוא מצב בו אין כדאיות כלכלית לתקן את הרכב במצב זה, לכן השמאי מוריד אותו מהכביש. להוראה זו של השמאי יש כמה השלכות',
      'Total loss-info-2-checkmark-1': 'כיוון שהרכב במצב זה בדרך כלל אינו מסוגל לנוע בכבישים, גרר של מגרש הגרוטאות גורר אותו למגרש כדי לבצע פעולות מקיפות של פירוק. חלק מהחלפים מועברים למחזור וחלפים שהם תקינים מועברים למכירה כחלקים משומשים אך תקינים',
      'Total loss-info-2-checkmark-2': 'על הנהג לדווח למשרד הרישוי שהרכב פורק ולכן יש להוריד אותו מרישום הרכבים שנעים בכבישי הארץ',
      'Total loss-info-2-checkmark-3': 'חברת הביטוח מעבירה תשלום לבעל הרכב על פי ערכו המלא, בהתאם לקביעה של השמאי',
      'Total loss-label-3': 'אובדן חלקי של ערך הרכב',
      'Total loss-info-3': 'לאחר תאונה קלה יחסית, שמאי הרכב מאשר לאנשי המוסך לבצע תיקונים ספציפיים אשר ישולמו על ידי חברת הביטוח של הנהג הפוגע עם זאת התיקונים הללו יוצרים ירידה של אחוזים מסוימים בערך הרכב. לכל חלק של הרכב יש אחוז מסוים כמו לדוגמה',
      'Total loss-info-3-example-1': 'כנף אחורית - X%',
      'Total loss-info-3-example-2': 'קורת שלדה - Y%',
      'Total loss-info-3-example-3': 'פגיעה בפח - Z%',
      'Total loss-label-4': 'הביטוח תמיד בתמונה',
      'Total loss-info-4': 'חברת הביטוח משלמת לבעל הרכב את סך ירידת הערך כיוון שנפגע במהלך תאונה שלא הייתה באשמתו ובעת מכירת הרכב יקבל סכום נמוך יותר מהשווי של הרכב עקב כך',
      'Rules-menu': 'כללים',
      'Rules-title': 'כללים לקניית רכב יד שניה',
      'Rules-subtitle': 'מה חייבים לעשות לפני שקונים רכב משומש',
      'Rules-label-1': 'מדברים עם בעל הרכב / הנהג',
      'Rules-info-1': 'בשלב הזה חשוב מאוד לבקש תמונות עדכניות של הרכב ולשאול מהו הקילומטראז’, מה בעלויות קודמות, היסטורית תקלות וטיפולים',
      'Rules-label-2': 'לבדוק את הרכב ברוורס',
      'Rules-info-2': 'עם רוורס אפשר להכיר את הרכב שאתם שוקלים לקנות כבר מרחוק עוד לפני שטרחתם ונסעתם לבדוק את מצבו ולפגוש את בעליו',
      'Rules-label-3': 'לבדוק את הרכב ברוורס',
      'Rules-info-3': 'עם רוורס אפשר להכיר את הרכב שאתם שוקלים לקנות כבר מרחוק עוד לפני שטרחתם ונסעתם לבדוק את מצבו ולפגוש את בעליו',
      'Rules-label-4': 'נסיעת מבחן ובדיקה ויזואלית חיצונית',
      'Rules-info-4': 'בחנו את האוטו מבחוץ פתחו את מכסה המנוע לחפש חיבורים רופפים, סימני נזילות, תוקף מצבר, וקח את הרכב לנסיעת מבחן, חשוב מאוד לסגור את הרדיו לפתוח חלונות ולהקשיב אם יש רעשים חריגים, לבדוק שהמזגן תקין',
      'Rules-label-5': 'וידוא פרטים',
      'Rules-info-5': 'יש לוודא שהפרטים תואמים והבעלים הם אכן הבעלים לפי רישיון הרכב ותעודת הזהות של המוכר',
      'Rules-info-5-important-1': 'קראו את קורות החיים של הרכב ברוורס האם לרכב היו תאונות עם ירידת ערך ואת מספר הקילומטרים האחרון שדווח זה הזמן לשקול – עסקה הוגנת או לא',
      'Rules-info-5-important-2': 'חשוב מאוד!',
      'Rules-label-6': 'לא קנית? הדו”ח שלך שווה כסף',
      'Rules-info-6': 'במידה והרכב לא מתאים לצרכים שלך זה המקום להעלות את הדוח קניה לרכב (הרי גם ככה לא תשתמש בו)',
      'Rules-info-6-upload-info': 'העלאה של הדוח תקנה לך 25% הנחה לדוח רוורס הבא',
      'Rules-info-6-upload-button': 'ניתן להעלות את הקובץ כאן',
      'Rules-label-7': 'הסכמים? יש לנו כבר נוסח מוכן',
      'Rules-info-7-upload-info': 'מסמך גילוי נאות וטופס אחרי בדיקה/זיכרון דברים',
      'Rules-info-7-upload-button': 'מורידים מהאתר וחותמים',
      'Rules-label-8': 'יוצאים לדרך חדשה, שיהיה בהצלחה!',
      'Car number': 'Car number',
    }
  },
  he: {
    translation: {
      "Home-Title": "בדיקת רכב לפני קנייה",
      "Home-Subtitle": "תאונות, שיעבוד, החלפת בעלויות",
      "Home-Sale": "עכשיו במחיר השקה רק - 79.90 ש״ח",
      "Home-Car number placeholder": "הזינו מספר רישוי",
      "Home-Check button": "בדקו",
      "Checking the seller vehicle": "בדיקת בעלויות לרכב",
      "Official mortgage check": "בדיקת משכונות לרכב",
      "Vehicle history from database": "קורות חיים לרכב",
      "Impairment test and total loss": "בדיקת טוטל לוס",
      "Maintenance costs and red flags": "כללים לבדיקת רכב יד שנייה",
      "Start with": "להתחיל עם זה",
      "Start with info": "רוורס לוקחת אתכם אחורה אל העבר של הרכב אוספת עבורכם מידע ומעניקה לכם את קורות חיי הרכב בדיוק בזמן .הנכון לפני רכישת רכב יד שנייה האם הוא עבר טוטאל לוס .אולי הרכב גנוב או מעוקל ? מה היסטורית הבעלויות שלו ?ייתכן ויש משהו בעבר שלו שמנסים להסתיר .כל מה שצריך לדעת – תוך 5 דקות, ישר למייל שלכם",
      "Check before seen": "לבדוק את האוטו, לפני שראית אותו",
      "Check before seen info": "המערכות שלנו אוספות פרטים מכל מאגרי המידע :הרשמיים לכם נשאר רק להקליק ולקבל תוך דקות את התוצאה בדיוק ברגע שזה רלוונטי לקנייה",
      "Vehicle CV": "קורות חיים של רכב - מה זה אומר",
      "Vehicle CV info": "הכי פשוט שיש: מסמך פשוט, קריא וברור שמגיע ישירות לאימייל שלכם ומפרט את הנתונים השונים שהרשויות יודעות על , הרכב המשומש שאתם שוקלים לקנות: תאונות , שעבודים, משכונים, ירידת ערך, היסטוריית בעלויות .תביעות ביטוח – הכל",
      "Check list": "מה בעצם בודקים פה",
      "Check list info": "רוורס לבעלויות הקודמות | רוורס למשכונים | רוורס להיסטורית הרכב | רוורס לירידת ערך וטוטאל לוס | רוורס לתחזוקה אזהרות וריקול",
      "Guide": "סרטון הדרכה",
      "How report looks": "הכירו את הדוח - איך זה בעצם נראה",
      "Full name": "שם מלא",
      "Full name require": "אנא הזן את השם המלא שלך!",
      "Phone number": "מספר טלפון",
      "Phone number require": "אנא הזן את מספר הטלפון שלך!",
      "Email address": "כתובת אימייל",
      "Email address require": "אנא הזן את האימייל שלך!",
      "Send": "שלח",
      "The commandments": "הדיברות",
      "Report of stolen vehicle": "דיווח על רכב גנוב",
      "Report of a car seller": "דיווח על מוכר רכב",
      "Accessibility": "נגישות",
      "To cancel an order": "לביטול הזמנה",
      "Terms": "תקנון",
      "Privacy Policy": "מדיניות פרטיות",
      "Contact us": "דברו איתנו ברוורס!",
      "Drawer title": "הגדרות",
      "User logged out": "משתמש התנתק",
      "Log In": "התחבר",
      "Logged as": "מחובר כ",
      "Logout": "התנתק",
      "Login account": "התחברות משתמש",
      "LOGIN": 'התחבר',
      "OR REGISTER": "או הירשם",
      "Something went wrong": "משהו השתבש",
      "User logged in": "משתמש התחבר",
      "Email address is not valid": "כתובת האימייל אינה חוקית",
      "Password require": "אנא הזן את הסיסמה שלך!",
      "Password": "סיסמה",
      "Reset password": "איפוס סיסמה",
      "Remember me": "זכור אותי",
      "Password conditions": "מינימום 8 תווים, לפחות אות 1 ומספר 1",
      "Confirm password": "אשר סיסמה",
      "Confirm password require": "אנא הזן את סיסמת האישור שלך!",
      "Passwords do not match": "סיסמאות לא תואמות",
      "SIGNUP": "הירשם",
      "BACK": "חזור",
      "Reset password - Alert description": "בקשה לאיפוס הסיסמה שלך נשלחה לכתובת האימייל שלך. אנא בדוק את תיבת הדואר הנכנס שלך כדי להמשיך בתהליך.",
      "Show report": "הצג דו״ח",
      "Show reports history": "הצג היסטוריית דוחות",
      "Created date": "תאריך יצירה",
      "File": "קובץ",
      "Main page": "עמוד ראשי",
      "Email sent successfully": "אימייל נשלח בהצלחה",
      "Email sent description": "קיבלנו את הפנייה שלך וניצור איתך קשר בהקדם",
      "Owner ID": "מספר תעודת הזהות של בעל הרכב הראשי",
      "Owner date": "תאריך תחילת הבעלות על הרכב (לפי רשיון הרכב)",
      "Report preparation alert": "תודה, הדו״ח שלך בתהליך הכנה ויכול לקחת מספר דקות. בסיום תוכל לצפות בו בעמוד הדוחות שלך.",
      "Search another car": "חפש רכב נוסף",
      "Report demo": "איך נראה הדוח",
      "Order report": "הזמינו דוח",
      'Why-menu': 'למה?',
      'Why-title': 'למה חייבים להיכנס לרוורס?',
      'Why-subtitle': 'המידע שרוורס מעניקה לכם יכול לחסוך קניה מיותרת של רכב פגום. מכאנית או משפטית רוצים לנסוע בראש שקט? כנסו לרוורס',
      'Why-label-1': 'בדיקת רכב מעוקל',
      'Why-info-1': 'בעל חוב אשר עושה כל שניתן כדי לקבל את כספו פותח תיק בהוצאה לפועל ועקב כך נרשם עיקול במשרד התחבורה על הרכב בעל מספר הרישוי הספציפי. מי שמעוניין לבצע העברת בעלות ממוחשבת באחד מסניפי רשות הדואר, אך מסתבר, שהרכב מעוקל, לא יוכל לבצע העברת בעלות',
      'Why-label-2': 'איך פותרים את הבעיה?',
      'Why-info-2': 'אם הרוכש מעוניין ברכב זה, קיימות שתי אופציות לפתרון וזאת רק אם הבעלים הנוכחיים של הרכב, מכיר בחוב ומבין שיש לשלם אותו',
      'Why-info-2-checkmark-1': 'הקונה ישלם את החוב וכך יוסר השעבוד מהרכב. בשלב שני הוא ישלם למוכר הרכב רק את היתרה שנותרה ממחיר הרכב שעליו סוכם בקיזוז ערך החוב ששולם.',
      'Why-info-2-checkmark-2': 'אם המוכר ישלם בעצמו את החוב ורק לאחר שיוכיח שהשעבוד בוטל, אפשר יהיה לבצע העברת בעלות ממוחשבת.',
      'Why-label-3': 'רכב משועבד',
      'Why-info-3': 'בעל רכב שקיבל מימון לצורך רכישת הרכב מפעיל שעבוד על הרכב, כיוון שהרכב משמש בטוחה על החזר כל כספי ההלוואה. לפני שקונים, יש לוודא שאין שיעבוד על הרכב ואת זה. בדיווק רוורס עושה עבורכם',
      'Why-label-4': 'רכב ממושכן ברשם המשכונות',
      'Why-info-4': 'דרך נוספת להעביר בעלות,היא רישום של פרטי רכב ספציפי ברשם המשכונות וכך למנוע את. מכירתו לאדם אחר',
      'Why-label-5': 'הדרך הפשוטה להימנע מסיבוכים',
      'Why-info-5': 'בדיקה קטנה יכולה לחסוך לכם כאב ראש גדול נכנסים לרוורס ומגלים את כל מה שלא סיפרו. לכם על הרכב שאתם עומדים לקנות',
      'Handicapped-menu': 'תו נכה',
      'Handicapped-title': 'תו נכה: לשים לב לפני שקונים',
      'Handicapped-subtitle': 'אתם הרי ממש לא רוצים להגיע למצב שאין באפשרותכם להעביר בעלות, נכון? אז כדי למנוע, סיבוכים מיותרים, יש לדאוג לבטל את תו הנכה(במידה וקיים). בטרם מכירת הרכב הנה כל מה שצריך לדעת כדי לעשות את זה נכון',
      'Handicapped-label-1': 'מה הוא תו נכה?',
      'Handicapped-info-1': 'תו נכה הוא רישיון לחנות במקומות חניה השמוריםהלנכים בלבד – מוכר על ידי הגורמים הרשמיים של. מדינת ישראל בעל רכב שעמד בקריטריונים וקיבל ממשרד הרישוי, תעודה של נכה אשר מקלה על חיי היום יום שלו מקבל תג נכה שמודבק על שמשת הרכב שלו והדבר: מופיע ברשומות של משרד הרישוי. נכים וחולים',
      'Handicapped-label-2': 'קונים רכב? לא לשכוח לבדוק את נושא תו הנכה',
      'Handicapped-info-2': 'אחד הדברים שצריך לבדוק כל מי שרוכש רכב יד שנייה הוא אם לרכב זה משוויך תג נכה אשר מאפשר לו לחנות במקומות חנייה מיוחדים היות ומי שרוכב רכב יד שנייה וימשיך להחזיק (אפילו לא ביודעין)',
      'Handicapped-label-3': 'אז מה עושים במשרד הרישוי?',
      'Handicapped-info-3': 'לאור ההטבות וההקלות בנושא חנייה עבור הנכים כאשר מוכרים רכב הנושא תו, משרד התחבורה לא מאפשר להעביר זכות זו של תג נכה לבעלים החדשים של הרכב כיוון שהם אינם מוכרים כנכים על ידי משרד הרישוי לכן, לפני ביצוע העברת הבעלות על בעל הרכב הנוכחי להחזיר למשרד הרישוי את תג הנכה, כדי שהסטטוס של מספר רישוי זה יהיה כמו כל רכב נורמטיבי ולא בעל זכאות ייחודית. רק לאחר. שמבצעים מהלך זה אפשר לבצע העברת בעלות',
      'Handicapped-label-4': 'קונים רכב? תבדקו מראש את נושא התו נכה',
      'Handicapped-info-4': 'מי שקונה רכב ואינו יודע שיש לו תו נכה, כשירצה לבצע העברת בעלות הוא ייתקל בסירוב, זאת, בשל תג הנכה. במקרים האלו הוא יבקש מבעל הרכב לבצע את המהלך הנדרש ורק לאחר מכן יוכל. לבצע את השינוי הנדרש',
      'Curved vehicle-menu': 'רכב מעוקל או משועבד',
      'Curved vehicle-title': 'רכב מעוקל או משועבד איך נמנעים מקנייה?',
      'Curved vehicle-subtitle': 'מה עושים כדי לא להגיע למצב שאתם עומדים לקנות רכב ואז מגלים שבעצם הבעלים הם לא לגמרי הבעלים? ניחשתם נכון: נכנסים לרוורס',
      'Curved vehicle-label-1': 'הצד המשפטי של הרכב הבא שלכם',
      'Curved vehicle-info-1': 'אדם שמעוניין לרכוש רכב יד שנייה, חייב לבדוק את מצבו של הרכב מבחינה מכנית, חשמלית וגם את הפאן המשפטי. יש מקרים שבהם הרכב מעוקל או משועבד ולכן יש להיות ערניים כדי לא להיקלע לצרה.',
      'Curved vehicle-label-2': 'רוורס פותרת את הבעיה',
      'Curved vehicle-info-2': 'המידע שמקבלים מקורות החיים של הרכב מרוורס מאפשר לדעת האם הרכב מעוקל או משועבד וכמו כן הרבה דברים אחרים שחשוב לבדוק ולדעת.',
      'Curved vehicle-label-3': 'העברת בעלות של הרכב',
      'Curved vehicle-info-3': 'מצאת רכב שנראה לך מתאים לדרישותיך? מצויין. עכשיו, כדאי לבדוק שגם המצב המשפטי שלו ראוי לעסקה',
      'Curved vehicle-label-4': 'שעבוד של רכב',
      'Curved vehicle-info-4': 'אדם שחייב כספים לגורמים שונים כמו ספקים, רשויות המס, או כל גורם אחר וקיים חשש שהוא לא יעמוד בהתחייבויות הכספיות שלו, בעל החוב פותח לו תיק בהוצאה לפועל. בדרך זו הוא מעקל כספים, נכסים ורכבים אשר קיימים ברשותו של אדם זה. המטרה היא לממש את הנכסים הללו, או חלק מהם כדי לקבל בדרך זו את התשלום אשר מגיע לגורם זה.',
      'Curved vehicle-label-5': 'משכון של רכב',
      'Curved vehicle-info-5': 'יש גורמים שמבצעים משכון לרכב כאן ברוורס, ניתן לדעת אם יש משכון ולמי',
      'Recall-menu': 'צו ריקול',
      'Recall-title': 'צו ריקול? יש חלק פסול!',
      'Recall-subtitle': 'ריקול הוא מצב שבו יצרנית רכב מכריזה על כך שיש חלק עם פגם בייצור ויש להחליפו בכל הרכבים. כשקונים רכב משומש יש לשים לב שהרכב שאתם קונים ביצע ריקול במידה והיה צריך',
      'Recall-label-1': 'כשיצרנית מלה פגם בייצור והרכבים כבר על הכביש',
      'Recall-info-1': 'יצרני הרכב בכל העולם מקדישים מחשבה רבה לכל נושא הבטיחות ברכב ולכן הם בוחנים את כל תהליכי התיקון והבדיקה, אשר קור מהרגע שהרכב נמכר וזאת כיוון שהרכבים מגיעים לטיפולים ולבדיקות שוטפות במוסכים בהתאם להוראות של יצרן הרכב.',
      'Recall-label-2': 'קריאת ריקול לרכבים',
      'Recall-info-2': 'אם קיימת תקלה שהיא חד פעמית או תקלה שחוזרת על עצמה שוב ושוב, מהנדסי הרכב בודקים זאת באופן מעמיק ולא אחת הם מגיעים למסקנה שיש בעיה טכנית בחלקים מסוימים ולכן יש להחליפם כדי להגן על הנהגים והנוסעים ולהעניק להם בטיחות מלאה, יצרני הרכב מייצרים את החלף החדש שיהיה מתאים יותר מהחלק המקורי. השלב הבא הוא קריאה לכל בעלי הרכב בדגם הספציפי על פי שנת הייצור להגיע לאחד המוסמכים המורשים כדי לבצע את ההחלפה. כל מי שרכש רכב מסוג זה, מקבל על כך הודעה באחד מאמצעי התקשורת ובהתאם לכך הוא מתאם תור למוסך מורשה כדי לבצע את ההחלפה בפועל.',
      'Recall-label-3': 'מחויבות לביצוע ריקול בפועל',
      'Recall-info-3': 'לאור החשיבות הרבה של ביצוע הריקול, כדי למנוע מצבים שהם לא בטיחותיים, כל בעל רכב מחויב לבצע פעולה זו מוקדם ככל האפשר',
      'Recall-label-4': 'ריקול, תנאי הכרחי כדי לעבור את הטסט',
      'Recall-info-4': 'בכל שנה כל רכב עובר מבחן רישוי שנתי כדי לבדוק את תקינותו. בעל רכב שמגיע למכון הרישוי ועל פי הבדיקה יסתבר שהוא לא פעל כנדרש ולא בוצעה החלפה של החלק הספציפי על פי הוראת היצרן, לא יוכל לעבור את מבחן הטסט השנתי רק לאחר שהוא יבצע את הפעולה הנדרשת, הוא יגיע שוב למכון הרישוי ואם הכול תקין, הוא יעבור את הטסט ויוכל להמשיך לנסוע ברכב',
      'Certified garage-menu': 'מוסך מורשה',
      'Certified garage-title': 'מוסך מורשה: מדוע הוא הכרחי?',
      'Certified garage-subtitle': 'משרד התחבורה מעניק רישיון ופיקוח בשבילנו ציבור הנהגים שרוצה לדעת שהרכב שלנו בידיים טובות',
      'Certified garage-label-1': 'מה זה מוסך?',
      'Certified garage-info-1': 'מוסכים מורשים מטעם משרד התחבורה הם מוסכים שנמצאים בפיקוח של משרד התחבורה והם בדרך כלל חברים באיגוד המוסכים',
      'Certified garage-label-2': 'מה הסטנדרט אליו מחויבים המוסכים המורשים?',
      'Certified garage-info-2': 'במוסך מורשה משרד התחבורה מעניקים טיפול מקצועי לרכבים מכל הסוגים ושל כל יצרני הרכב אשר הרכבים המיובאים ארצה. המוסכים הללו מחויבים לפעול על פי תקנות מחמירות. עובדי מוסך עוברים הכשרות והדרכות מקצועיות בכל הקשור לאופן הבדיקה, התיקון ותחזוקה של כל אחד מהרכבים של היצרנים השונים. בראש המוסך מחויב לעמוד מכונאי בעל הכשרות ספציפיות, המנהל את המערך המקצועי',
      'Certified garage-label-3': 'שקיפות: ככה עובד מוסך מורשה',
      'Certified garage-info-3': 'במוסך מסוג זה חייב להיות תלוי מחירון במקום גלוי לעין. שם מפורטות העלויות של שעת העבודה, ושל העלות של החלפים המקוריים. עובדי המוסך שמקבלים רכב לטיפול, מחויבים לאחר הבדיקה ליידע את הלקוח מהם הממצאים שלהם, מה יש לתקן ומהן העלויות של התיקון, החלפים ושעות העבודה. רק לאחר אישורו של הלקוח לביצוע העבודה, צוות העובדים מבצע זאת בפועל',
      'Certified garage-label-4': 'תיעוד של הפעולות במוסך מורשה',
      'Certified garage-info-4': 'במוסך מורשה, כל הנתונים על התיקונים השונים שבוצעו ברכב, אילו חלפים הוחלפו, מתי בוצעו הטיפולים השוטפים וכל מידע אחר, מפורטים בתוכנה הממוחשבת. כך אפשר לבדוק לדוגמה מתי הוחלפו הבלמים, רצועת הטיימינג וכו’. מידע זה חשוב לכל בעל רכב ובמיוחד בעת רכישת רכב יד שנייה וכך הוא רואה את מלוא האינפורמציה באופן מרוכז',
      'Certified garage-label-5': 'מוסכי מדרכה – איך מזהים ונזהרים?',
      'Certified garage-info-5': 'מוסכי מדרכה הם מוסכים פיראטיים אשר פועלים ללא פיקוח. לכן לא אחת הנהגים לא מרוצים מהטיפולים שבוצעו ברכב כיוון שאין למי לפנות במקרה של בעיה ואין עליהם פיקוח של משרד התחבורה. המחירים במוסכים הללו הם גבוהים ולא תמיד מותקנים ברכבים המגיעים לתיקון חלפים מקוריים או חדשים. יש חלפים משומשים שמותקנים ברכבים שהם מחזיקים מעמד פרק זמן קצר, או שהם לא תקינים לאור כל זאת ההמלצה היא לא לתקן את הרכבים במוסכי מדרכה',
      'Car dealer-menu': 'סוחר רכב מורשה',
      'Car dealer-title': 'סוחר רכב מורשה: למה הוא חשוב בעצם?',
      'Car dealer-subtitle': 'רוצים לקנות רכב בלי להסתכן בעסקה מפוקפקת? קונים מסוחר רכב מורשה המחזיק ברישיון ממשרד התחבורה ונוסעים בראש שקט',
      'Car dealer-label-1': 'מהו סוחר רכב מורשה?',
      'Car dealer-info-1': 'סוחר רכב מורשה הוא בעל מקצוע שקיבל אישור על כך שהעסק שלו בתחום המסחר במכוניות הוא מפוקח ולכן הוא מאושר לתפעול על ידי משרד התחבורה.',
      'Car dealer-label-2': 'היתרונות לקניה מסוחר רכב',
      'Car dealer-info-2-checkmark-1': 'קנייה ממקום עם אחריות',
      'Car dealer-info-2-checkmark-2': 'קנייה ממקום אשר דואג למוניטין שלו',
      'Car dealer-info-2-checkmark-3': 'קנייה מבעל מקצוע אשר אמון לדעת ולהבין את מאפייני הרכב ואחראי עליהם',
      'Car dealer-info-2-checkmark-4': 'קבלת רכב לאחר השבחה',
      'Car dealer-label-3': 'מטפלים ברכב ומוכרים אותו',
      'Car dealer-info-3': 'המגמה של כל אחד מסוחרי הרכב היא לרכוש רכבים שונים בעלות שהיא נמוכה ממחיר המחירון ולאחר מכן הם מעבירים את הרכב לטיפול של אנשי מקצוע שהם עובדים איתם בשיתוף פעולה כמו חשמלאי רכב, מכונאי רכב, איש פחחות, איש צבע ועוד בהתאם למצבו שלה הרכב הוא מזמין מהם את ביצוע הטיפולים הנדרשים כדי שאפשר יהיה למכור רכב תקין, כמו לדוגמה צבע ותיקוני פחחות. לאחר מכן כל רכב עובר ניקיון יסודי פנימי וחיצוני וכך הוא מוכן למכירה בשלב הבא, מפרסם את הרכב למכירה במדיות השונות.',
      'Car dealer-label-4': 'קנייה של רכבים משומשים',
      'Car dealer-info-4': 'סוחרי הרכב קונים רכבים במכרזים, מגרשים של רכבי טרייד-אין ומאנשים פרטיים. הסוחרים מחזיקים בתו סחר שמאפשר להם לטפל ברכב עד מכירתו ללא צורך בהעברת בעלות וכך לא נרשמת על הרכב יד נוספת. בנוסף לכך, תו זה מאפשר להקפיא את מועד הטסט על מכירת הרכב והבעלים החדשים דואג לתשלום הטסט ולהעברת הרכב במבחן השנתי לגבי תקינותו.',
      'How it works-menu': 'איך זה עובד?',
      'How it works-title': 'איך זה בעצם עובד?',
      'How it works-subtitle': 'שלושה שלבים ויש לכם קורות חיים של רכב עם נתונים ותובנות לצאת לדרך',
      'How it works-label-1': 'חלק ראשון: רוורס 7 שנים אחורה',
      'How it works-info-1': 'מציידים אתכם במידע על הנקודות הרגישות ביותר בקניית רכב משומש',
      'How it works-info-1-checkmark-1': 'מעורבות בתאונות?',
      'How it works-info-1-checkmark-2': 'ירידת ערך עקב תאונות?',
      'How it works-info-1-checkmark-3': 'האם בעבר הרכב נגנב?',
      'How it works-info-1-checkmark-4': 'האם הרכב היה מבוטח?',
      'How it works-info-1-checkmark-5': '?האם הרכב הוכרז כטוטאל לוס',
      'How it works-info-1-checkmark-6': '?האם שולמו פיצויים מחברות הביטוח',
      'How it works-label-2': 'שלב שני: בעלויות קודמות',
      'How it works-info-2': 'מי שמוכר רכב יד שלישית אולי לא יודע הכל על העבר של הרכ',
      'How it works-info-2-checkmark-1': '?האם הרכב היה בבעלות גורם מסחרי בעבר',
      'How it works-info-2-checkmark-2': '?אולי יש סיבה לחשוד באחת הבעלויות הקודמות',
      'How it works-label-3': 'שלב שלישי: מסקנות וכלים לעסקה נכונה',
      'How it works-info-3': 'מציידים אתכם במידע על הנקודות הרגישות ביותר בקניית רכב משומש',
      'How it works-info-3-checkmark-1': 'האם ישנם נתונים בעייתיים שכדאי לשים לב אליהם?',
      'How it works-info-3-checkmark-2': 'האם דווקא הרכב הזה עושה רושם טוב?',
      'How it works-info-3-checkmark-3': '?מה הבינה המלאכותית שלנו אומרת על ההזדמנות',
      'How it works-info-4': 'מערכת הבינה המלאכותית מסייעת לכם עם תובנות, סיכומים ומסקנות, בעצם חושבת ביחד אתכם: מה הצעד שהכי נכון לעשות עכשיו',
      'How it works-info-4-checkmark-1': '?בכמה כדאי לקנות',
      'How it works-info-4-checkmark-2': '?ירידת ערך – מה אומרת התחזית שלנו',
      'How it works-info-4-checkmark-3': '?כמה יעלה לתחזק את הרכב הזה',
      'How it works-info-4-checkmark-4': 'טפסי מכירה',
      'How it works-info-4-checkmark-5': '?הנחיות וטיפים: כיצד יש לנהוג במכירה או קנייה',
      'Total loss-menu': 'טוטאל לוס',
      'Total loss-title': 'טוטאל לוס? איך לא קונים רכב כזה',
      'Total loss-subtitle': 'טוטאל לוס הוא מצב שבו רכב מורד מהכביש לאחר שהנזק שנגרם מתאונה שווה ל60% ומעלה מערכו. מה התהליך שמאחורי הכרזה על רכב כזה? כל המידע לפניכם',
      'Total loss-label-1': 'שמאי רכב – איש המקצוע בדיוק למצבים האלה',
      'Total loss-info-1': 'תו נכה הוא רישיון לחנות במקומות חניה השמוריםהלנכים בלבד – מוכר על ידי הגורמים הרשמיים של. מדינת ישראל בעל רכב שעמד בקריטריונים וקיבל ממשרד הרישוי, תעודה של נכה אשר מקלה על חיי היום יום שלו מקבל תג נכה שמודבק על שמשת הרכב שלו והדבר: מופיע ברשומות של משרד הרישוי. נכים וחולים',
      'Total loss-label-2': 'טוטאל לוס – מה זה?',
      'Total loss-info-2': 'טוטאל לוס הוא מצב בו אין כדאיות כלכלית לתקן את הרכב במצב זה, לכן השמאי מוריד אותו מהכביש. להוראה זו של השמאי יש כמה השלכות',
      'Total loss-info-2-checkmark-1': 'כיוון שהרכב במצב זה בדרך כלל אינו מסוגל לנוע בכבישים, גרר של מגרש הגרוטאות גורר אותו למגרש כדי לבצע פעולות מקיפות של פירוק. חלק מהחלפים מועברים למחזור וחלפים שהם תקינים מועברים למכירה כחלקים משומשים אך תקינים',
      'Total loss-info-2-checkmark-2': 'על הנהג לדווח למשרד הרישוי שהרכב פורק ולכן יש להוריד אותו מרישום הרכבים שנעים בכבישי הארץ',
      'Total loss-info-2-checkmark-3': 'חברת הביטוח מעבירה תשלום לבעל הרכב על פי ערכו המלא, בהתאם לקביעה של השמאי',
      'Total loss-label-3': 'אובדן חלקי של ערך הרכב',
      'Total loss-info-3': 'לאחר תאונה קלה יחסית, שמאי הרכב מאשר לאנשי המוסך לבצע תיקונים ספציפיים אשר ישולמו על ידי חברת הביטוח של הנהג הפוגע עם זאת התיקונים הללו יוצרים ירידה של אחוזים מסוימים בערך הרכב. לכל חלק של הרכב יש אחוז מסוים כמו לדוגמה',
      'Total loss-info-3-example-1': 'כנף אחורית - X%',
      'Total loss-info-3-example-2': 'קורת שלדה - Y%',
      'Total loss-info-3-example-3': 'פגיעה בפח - Z%',
      'Total loss-label-4': 'הביטוח תמיד בתמונה',
      'Total loss-info-4': 'חברת הביטוח משלמת לבעל הרכב את סך ירידת הערך כיוון שנפגע במהלך תאונה שלא הייתה באשמתו ובעת מכירת הרכב יקבל סכום נמוך יותר מהשווי של הרכב עקב כך',
      'Rules-menu': 'כללים',
      'Rules-title': 'כללים לקניית רכב יד שניה',
      'Rules-subtitle': 'מה חייבים לעשות לפני שקונים רכב משומש',
      'Rules-label-1': 'מדברים עם בעל הרכב / הנהג',
      'Rules-info-1': 'בשלב הזה חשוב מאוד לבקש תמונות עדכניות של הרכב ולשאול מהו הקילומטראז’, מה בעלויות קודמות, היסטורית תקלות וטיפולים',
      'Rules-label-2': 'לבדוק את הרכב ברוורס',
      'Rules-info-2': 'עם רוורס אפשר להכיר את הרכב שאתם שוקלים לקנות כבר מרחוק עוד לפני שטרחתם ונסעתם לבדוק את מצבו ולפגוש את בעליו',
      'Rules-label-3': 'לבדוק את הרכב ברוורס',
      'Rules-info-3': 'עם רוורס אפשר להכיר את הרכב שאתם שוקלים לקנות כבר מרחוק עוד לפני שטרחתם ונסעתם לבדוק את מצבו ולפגוש את בעליו',
      'Rules-label-4': 'נסיעת מבחן ובדיקה ויזואלית חיצונית',
      'Rules-info-4': 'בחנו את האוטו מבחוץ פתחו את מכסה המנוע לחפש חיבורים רופפים, סימני נזילות, תוקף מצבר, וקח את הרכב לנסיעת מבחן, חשוב מאוד לסגור את הרדיו לפתוח חלונות ולהקשיב אם יש רעשים חריגים, לבדוק שהמזגן תקין',
      'Rules-label-5': 'וידוא פרטים',
      'Rules-info-5': 'יש לוודא שהפרטים תואמים והבעלים הם אכן הבעלים לפי רישיון הרכב ותעודת הזהות של המוכר',
      'Rules-info-5-important-1': 'קראו את קורות החיים של הרכב ברוורס האם לרכב היו תאונות עם ירידת ערך ואת מספר הקילומטרים האחרון שדווח זה הזמן לשקול – עסקה הוגנת או לא',
      'Rules-info-5-important-2': 'חשוב מאוד!',
      'Rules-label-6': 'לא קנית? הדו”ח שלך שווה כסף',
      'Rules-info-6': 'במידה והרכב לא מתאים לצרכים שלך זה המקום להעלות את הדוח קניה לרכב (הרי גם ככה לא תשתמש בו)',
      'Rules-info-6-upload-info': 'העלאה של הדוח תקנה לך 25% הנחה לדוח רוורס הבא',
      'Rules-info-6-upload-button': 'ניתן להעלות את הקובץ כאן',
      'Rules-label-7': 'הסכמים? יש לנו כבר נוסח מוכן',
      'Rules-info-7-upload-info': 'מסמך גילוי נאות וטופס אחרי בדיקה/זיכרון דברים',
      'Rules-info-7-upload-button': 'מורידים מהאתר וחותמים',
      'Rules-label-8': 'יוצאים לדרך חדשה, שיהיה בהצלחה!',
      'Car number': 'מספר רכב',
    }
  },
  ru: {
    translation: {
      'Home-Title': 'Проверка автомобиля перед покупкой.',
      'Home-Subtitle': 'Аварии, заклад, смена владельца.',
      'Home-Sale': 'Сейчас по цене старта - всего 79.90 шекелей.',
      'Home-Car number placeholder': 'Введите № лицензии',
      'Home-Check button': 'проверьте',
      "Checking the seller vehicle": "Проверка прав собственности на автомобиль",
      "Official mortgage check": "Проверка автокредита",
      "Vehicle history from database": "Биография автомобиля",
      "Impairment test and total loss": "Проверка суммарных потерь",
      "Maintenance costs and red flags": "Правила проверки подержанного автомобиля",
      "Start with": "С чего начинать:",
      "Start with info": "Реверс отправляется с вами назад, в прошлое автомобиля, собирает для вас информацию и предоставляет вам биографию машины – все точно в срок, перед приобретением вами подержанной машины, имеет ли она суммарные потери, может, она ворованная или арестованная? Какова история собственности на нее? Может, есть что-то в ее прошлом, что хотят скрыть. Все, что нужно знать – в течение 5 минут у вас в электронной почте.",
      "Check before seen": "Проверить автомобиль, еще до того, как увидели его.",
      "Check before seen info": "Наша система собирает информацию из всех официальных источников, вам остается только щелкнуть – и через считанные минуты вы получите информацию, как раз к моменту, подходящему для покупки.",
      "Vehicle CV": "Биография автомобиля – что это?",
      "Vehicle CV info": "Наши системы собирают данные из всех официальных источников информации, вам остается только щелкнуть – и через считанные минуты вы получите информацию, как раз к моменту, подходящему для покупки.",
      "Check list": "Что в сущности проверяется здесь?",
      "Check list info": "Реверс к прежним владельцам | реверс к залогам | реверс к истории машины | реверс к снижению ценности и суммарным потерям | реверс к техническому обслуживанию, предостережениям и отзыву автомобиля для устранения дефектов производства",
      "Guide": "Видеоролик с инструкциями",
      "How report looks": "Want to see what a check car report looks like",
      "Full name": "Полное имя",
      "Full name require": "Please input your full name!",
      "Phone number": "Телефон",
      "Phone number require": "Please input your phone number!",
      "Email address": "Электронная почта",
      "Email address require": "Please input your email!",
      "Send": "отправить",
      "The commandments": "The commandments",
      "Report of stolen vehicle": "Report of stolen vehicle",
      "Report of a car seller": "Report of a car seller",
      "Accessibility": "Accessibility",
      "To cancel an order": "To cancel an order",
      "Terms": "Terms",
      "Privacy Policy": "Privacy Policy",
      "Contact us": "Обращайтесь к нам!",
      "Drawer title": "Settings",
      "User logged out": "User logged out",
      "Log In": "Log In",
      "Logged as": "Logged as",
      "Logout": "Logout",
      "Login account": "Login account",
      "LOGIN": "LOGIN",
      "OR REGISTER": "OR REGISTER",
      "Something went wrong": "Something went wrong",
      "User logged in": "User logged in",
      "Email address is not valid": "Email address is not valid",
      "Password require": "Please input your password!",
      "Password": "Password",
      "Reset password": "Reset password",
      "Remember me": "Remember me",
      "Password conditions": "Min 8 chars, at least 1 letter and 1 number",
      "Confirm password": "Confirm password",
      "Confirm password require": "Please input your confirm password!",
      "Passwords do not match": "Passwords do not match",
      "SIGNUP": "SIGNUP",
      "RESET PASSWORD": "RESET PASSWORD",
      "BACK": "BACK",
      "Reset password - Alert description": "A request to reset your password has been sent to your email address. Please check your inbox to continue the process.",
      "Show report": "Show report",
      "Show reports history": "Show reports history",
      "Created date": "Created date",
      "File": "File",
      "Main page": "Main page",
      "Email sent successfully": "Email sent successfully",
      "Email sent description": "We have received your inquiry and we will contact you soon",
      "Owner ID": "ID number of the main vehicle owner",
      "Owner date": "Date of commencement of ownership of the vehicle (according to the vehicle license)",
      "Report preparation alert": "Thanks, your report is in preparation and may take several minutes. When done, you can view it on your reports page.",
      "Search another car": "Search another car",
      "Report demo": "Report demo",
      "Order report": "Order report",
      'Why-menu': 'למה?',
      'Why-title': 'למה חייבים להיכנס לרוורס?',
      'Why-subtitle': 'המידע שרוורס מעניקה לכם יכול לחסוך קניה מיותרת של רכב פגום. מכאנית או משפטית רוצים לנסוע בראש שקט? כנסו לרוורס',
      'Why-label-1': 'בדיקת רכב מעוקל',
      'Why-info-1': 'בעל חוב אשר עושה כל שניתן כדי לקבל את כספו פותח תיק בהוצאה לפועל ועקב כך נרשם עיקול במשרד התחבורה על הרכב בעל מספר הרישוי הספציפי. מי שמעוניין לבצע העברת בעלות ממוחשבת באחד מסניפי רשות הדואר, אך מסתבר, שהרכב מעוקל, לא יוכל לבצע העברת בעלות',
      'Why-label-2': 'איך פותרים את הבעיה?',
      'Why-info-2': 'אם הרוכש מעוניין ברכב זה, קיימות שתי אופציות לפתרון וזאת רק אם הבעלים הנוכחיים של הרכב, מכיר בחוב ומבין שיש לשלם אותו',
      'Why-info-2-checkmark-1': 'הקונה ישלם את החוב וכך יוסר השעבוד מהרכב. בשלב שני הוא ישלם למוכר הרכב רק את היתרה שנותרה ממחיר הרכב שעליו סוכם בקיזוז ערך החוב ששולם.',
      'Why-info-2-checkmark-2': 'אם המוכר ישלם בעצמו את החוב ורק לאחר שיוכיח שהשעבוד בוטל, אפשר יהיה לבצע העברת בעלות ממוחשבת.',
      'Why-label-3': 'רכב משועבד',
      'Why-info-3': 'בעל רכב שקיבל מימון לצורך רכישת הרכב מפעיל שעבוד על הרכב, כיוון שהרכב משמש בטוחה על החזר כל כספי ההלוואה. לפני שקונים, יש לוודא שאין שיעבוד על הרכב ואת זה. בדיווק רוורס עושה עבורכם',
      'Why-label-4': 'רכב ממושכן ברשם המשכונות',
      'Why-info-4': 'דרך נוספת להעביר בעלות,היא רישום של פרטי רכב ספציפי ברשם המשכונות וכך למנוע את. מכירתו לאדם אחר',
      'Why-label-5': 'הדרך הפשוטה להימנע מסיבוכים',
      'Why-info-5': 'בדיקה קטנה יכולה לחסוך לכם כאב ראש גדול נכנסים לרוורס ומגלים את כל מה שלא סיפרו. לכם על הרכב שאתם עומדים לקנות',
      'Handicapped-menu': 'תו נכה',
      'Handicapped-title': 'תו נכה: לשים לב לפני שקונים',
      'Handicapped-subtitle': 'אתם הרי ממש לא רוצים להגיע למצב שאין באפשרותכם להעביר בעלות, נכון? אז כדי למנוע, סיבוכים מיותרים, יש לדאוג לבטל את תו הנכה(במידה וקיים). בטרם מכירת הרכב הנה כל מה שצריך לדעת כדי לעשות את זה נכון',
      'Handicapped-label-1': 'מה הוא תו נכה?',
      'Handicapped-info-1': 'תו נכה הוא רישיון לחנות במקומות חניה השמוריםהלנכים בלבד – מוכר על ידי הגורמים הרשמיים של. מדינת ישראל בעל רכב שעמד בקריטריונים וקיבל ממשרד הרישוי, תעודה של נכה אשר מקלה על חיי היום יום שלו מקבל תג נכה שמודבק על שמשת הרכב שלו והדבר: מופיע ברשומות של משרד הרישוי. נכים וחולים',
      'Handicapped-label-2': 'קונים רכב? לא לשכוח לבדוק את נושא תו הנכה',
      'Handicapped-info-2': 'אחד הדברים שצריך לבדוק כל מי שרוכש רכב יד שנייה הוא אם לרכב זה משוויך תג נכה אשר מאפשר לו לחנות במקומות חנייה מיוחדים היות ומי שרוכב רכב יד שנייה וימשיך להחזיק (אפילו לא ביודעין)',
      'Handicapped-label-3': 'אז מה עושים במשרד הרישוי?',
      'Handicapped-info-3': 'לאור ההטבות וההקלות בנושא חנייה עבור הנכים כאשר מוכרים רכב הנושא תו, משרד התחבורה לא מאפשר להעביר זכות זו של תג נכה לבעלים החדשים של הרכב כיוון שהם אינם מוכרים כנכים על ידי משרד הרישוי לכן, לפני ביצוע העברת הבעלות על בעל הרכב הנוכחי להחזיר למשרד הרישוי את תג הנכה, כדי שהסטטוס של מספר רישוי זה יהיה כמו כל רכב נורמטיבי ולא בעל זכאות ייחודית. רק לאחר. שמבצעים מהלך זה אפשר לבצע העברת בעלות',
      'Handicapped-label-4': 'קונים רכב? תבדקו מראש את נושא התו נכה',
      'Handicapped-info-4': 'מי שקונה רכב ואינו יודע שיש לו תו נכה, כשירצה לבצע העברת בעלות הוא ייתקל בסירוב, זאת, בשל תג הנכה. במקרים האלו הוא יבקש מבעל הרכב לבצע את המהלך הנדרש ורק לאחר מכן יוכל. לבצע את השינוי הנדרש',
      'Curved vehicle-menu': 'רכב מעוקל או משועבד',
      'Curved vehicle-title': 'רכב מעוקל או משועבד איך נמנעים מקנייה?',
      'Curved vehicle-subtitle': 'מה עושים כדי לא להגיע למצב שאתם עומדים לקנות רכב ואז מגלים שבעצם הבעלים הם לא לגמרי הבעלים? ניחשתם נכון: נכנסים לרוורס',
      'Curved vehicle-label-1': 'הצד המשפטי של הרכב הבא שלכם',
      'Curved vehicle-info-1': 'אדם שמעוניין לרכוש רכב יד שנייה, חייב לבדוק את מצבו של הרכב מבחינה מכנית, חשמלית וגם את הפאן המשפטי. יש מקרים שבהם הרכב מעוקל או משועבד ולכן יש להיות ערניים כדי לא להיקלע לצרה.',
      'Curved vehicle-label-2': 'רוורס פותרת את הבעיה',
      'Curved vehicle-info-2': 'המידע שמקבלים מקורות החיים של הרכב מרוורס מאפשר לדעת האם הרכב מעוקל או משועבד וכמו כן הרבה דברים אחרים שחשוב לבדוק ולדעת.',
      'Curved vehicle-label-3': 'העברת בעלות של הרכב',
      'Curved vehicle-info-3': 'מצאת רכב שנראה לך מתאים לדרישותיך? מצויין. עכשיו, כדאי לבדוק שגם המצב המשפטי שלו ראוי לעסקה',
      'Curved vehicle-label-4': 'שעבוד של רכב',
      'Curved vehicle-info-4': 'אדם שחייב כספים לגורמים שונים כמו ספקים, רשויות המס, או כל גורם אחר וקיים חשש שהוא לא יעמוד בהתחייבויות הכספיות שלו, בעל החוב פותח לו תיק בהוצאה לפועל. בדרך זו הוא מעקל כספים, נכסים ורכבים אשר קיימים ברשותו של אדם זה. המטרה היא לממש את הנכסים הללו, או חלק מהם כדי לקבל בדרך זו את התשלום אשר מגיע לגורם זה.',
      'Curved vehicle-label-5': 'משכון של רכב',
      'Curved vehicle-info-5': 'יש גורמים שמבצעים משכון לרכב כאן ברוורס, ניתן לדעת אם יש משכון ולמי',
      'Recall-menu': 'צו ריקול',
      'Recall-title': 'צו ריקול? יש חלק פסול!',
      'Recall-subtitle': 'ריקול הוא מצב שבו יצרנית רכב מכריזה על כך שיש חלק עם פגם בייצור ויש להחליפו בכל הרכבים. כשקונים רכב משומש יש לשים לב שהרכב שאתם קונים ביצע ריקול במידה והיה צריך',
      'Recall-label-1': 'כשיצרנית מלה פגם בייצור והרכבים כבר על הכביש',
      'Recall-info-1': 'יצרני הרכב בכל העולם מקדישים מחשבה רבה לכל נושא הבטיחות ברכב ולכן הם בוחנים את כל תהליכי התיקון והבדיקה, אשר קור מהרגע שהרכב נמכר וזאת כיוון שהרכבים מגיעים לטיפולים ולבדיקות שוטפות במוסכים בהתאם להוראות של יצרן הרכב.',
      'Recall-label-2': 'קריאת ריקול לרכבים',
      'Recall-info-2': 'אם קיימת תקלה שהיא חד פעמית או תקלה שחוזרת על עצמה שוב ושוב, מהנדסי הרכב בודקים זאת באופן מעמיק ולא אחת הם מגיעים למסקנה שיש בעיה טכנית בחלקים מסוימים ולכן יש להחליפם כדי להגן על הנהגים והנוסעים ולהעניק להם בטיחות מלאה, יצרני הרכב מייצרים את החלף החדש שיהיה מתאים יותר מהחלק המקורי. השלב הבא הוא קריאה לכל בעלי הרכב בדגם הספציפי על פי שנת הייצור להגיע לאחד המוסמכים המורשים כדי לבצע את ההחלפה. כל מי שרכש רכב מסוג זה, מקבל על כך הודעה באחד מאמצעי התקשורת ובהתאם לכך הוא מתאם תור למוסך מורשה כדי לבצע את ההחלפה בפועל.',
      'Recall-label-3': 'מחויבות לביצוע ריקול בפועל',
      'Recall-info-3': 'לאור החשיבות הרבה של ביצוע הריקול, כדי למנוע מצבים שהם לא בטיחותיים, כל בעל רכב מחויב לבצע פעולה זו מוקדם ככל האפשר',
      'Recall-label-4': 'ריקול, תנאי הכרחי כדי לעבור את הטסט',
      'Recall-info-4': 'בכל שנה כל רכב עובר מבחן רישוי שנתי כדי לבדוק את תקינותו. בעל רכב שמגיע למכון הרישוי ועל פי הבדיקה יסתבר שהוא לא פעל כנדרש ולא בוצעה החלפה של החלק הספציפי על פי הוראת היצרן, לא יוכל לעבור את מבחן הטסט השנתי רק לאחר שהוא יבצע את הפעולה הנדרשת, הוא יגיע שוב למכון הרישוי ואם הכול תקין, הוא יעבור את הטסט ויוכל להמשיך לנסוע ברכב',
      'Certified garage-menu': 'מוסך מורשה',
      'Certified garage-title': 'מוסך מורשה: מדוע הוא הכרחי?',
      'Certified garage-subtitle': 'משרד התחבורה מעניק רישיון ופיקוח בשבילנו ציבור הנהגים שרוצה לדעת שהרכב שלנו בידיים טובות',
      'Certified garage-label-1': 'מה זה מוסך?',
      'Certified garage-info-1': 'מוסכים מורשים מטעם משרד התחבורה הם מוסכים שנמצאים בפיקוח של משרד התחבורה והם בדרך כלל חברים באיגוד המוסכים',
      'Certified garage-label-2': 'מה הסטנדרט אליו מחויבים המוסכים המורשים?',
      'Certified garage-info-2': 'במוסך מורשה משרד התחבורה מעניקים טיפול מקצועי לרכבים מכל הסוגים ושל כל יצרני הרכב אשר הרכבים המיובאים ארצה. המוסכים הללו מחויבים לפעול על פי תקנות מחמירות. עובדי מוסך עוברים הכשרות והדרכות מקצועיות בכל הקשור לאופן הבדיקה, התיקון ותחזוקה של כל אחד מהרכבים של היצרנים השונים. בראש המוסך מחויב לעמוד מכונאי בעל הכשרות ספציפיות, המנהל את המערך המקצועי',
      'Certified garage-label-3': 'שקיפות: ככה עובד מוסך מורשה',
      'Certified garage-info-3': 'במוסך מסוג זה חייב להיות תלוי מחירון במקום גלוי לעין. שם מפורטות העלויות של שעת העבודה, ושל העלות של החלפים המקוריים. עובדי המוסך שמקבלים רכב לטיפול, מחויבים לאחר הבדיקה ליידע את הלקוח מהם הממצאים שלהם, מה יש לתקן ומהן העלויות של התיקון, החלפים ושעות העבודה. רק לאחר אישורו של הלקוח לביצוע העבודה, צוות העובדים מבצע זאת בפועל',
      'Certified garage-label-4': 'תיעוד של הפעולות במוסך מורשה',
      'Certified garage-info-4': 'במוסך מורשה, כל הנתונים על התיקונים השונים שבוצעו ברכב, אילו חלפים הוחלפו, מתי בוצעו הטיפולים השוטפים וכל מידע אחר, מפורטים בתוכנה הממוחשבת. כך אפשר לבדוק לדוגמה מתי הוחלפו הבלמים, רצועת הטיימינג וכו’. מידע זה חשוב לכל בעל רכב ובמיוחד בעת רכישת רכב יד שנייה וכך הוא רואה את מלוא האינפורמציה באופן מרוכז',
      'Certified garage-label-5': 'מוסכי מדרכה – איך מזהים ונזהרים?',
      'Certified garage-info-5': 'מוסכי מדרכה הם מוסכים פיראטיים אשר פועלים ללא פיקוח. לכן לא אחת הנהגים לא מרוצים מהטיפולים שבוצעו ברכב כיוון שאין למי לפנות במקרה של בעיה ואין עליהם פיקוח של משרד התחבורה. המחירים במוסכים הללו הם גבוהים ולא תמיד מותקנים ברכבים המגיעים לתיקון חלפים מקוריים או חדשים. יש חלפים משומשים שמותקנים ברכבים שהם מחזיקים מעמד פרק זמן קצר, או שהם לא תקינים לאור כל זאת ההמלצה היא לא לתקן את הרכבים במוסכי מדרכה',
      'Car dealer-menu': 'סוחר רכב מורשה',
      'Car dealer-title': 'סוחר רכב מורשה: למה הוא חשוב בעצם?',
      'Car dealer-subtitle': 'רוצים לקנות רכב בלי להסתכן בעסקה מפוקפקת? קונים מסוחר רכב מורשה המחזיק ברישיון ממשרד התחבורה ונוסעים בראש שקט',
      'Car dealer-label-1': 'מהו סוחר רכב מורשה?',
      'Car dealer-info-1': 'סוחר רכב מורשה הוא בעל מקצוע שקיבל אישור על כך שהעסק שלו בתחום המסחר במכוניות הוא מפוקח ולכן הוא מאושר לתפעול על ידי משרד התחבורה.',
      'Car dealer-label-2': 'היתרונות לקניה מסוחר רכב',
      'Car dealer-info-2-checkmark-1': 'קנייה ממקום עם אחריות',
      'Car dealer-info-2-checkmark-2': 'קנייה ממקום אשר דואג למוניטין שלו',
      'Car dealer-info-2-checkmark-3': 'קנייה מבעל מקצוע אשר אמון לדעת ולהבין את מאפייני הרכב ואחראי עליהם',
      'Car dealer-info-2-checkmark-4': 'קבלת רכב לאחר השבחה',
      'Car dealer-label-3': 'מטפלים ברכב ומוכרים אותו',
      'Car dealer-info-3': 'המגמה של כל אחד מסוחרי הרכב היא לרכוש רכבים שונים בעלות שהיא נמוכה ממחיר המחירון ולאחר מכן הם מעבירים את הרכב לטיפול של אנשי מקצוע שהם עובדים איתם בשיתוף פעולה כמו חשמלאי רכב, מכונאי רכב, איש פחחות, איש צבע ועוד בהתאם למצבו שלה הרכב הוא מזמין מהם את ביצוע הטיפולים הנדרשים כדי שאפשר יהיה למכור רכב תקין, כמו לדוגמה צבע ותיקוני פחחות. לאחר מכן כל רכב עובר ניקיון יסודי פנימי וחיצוני וכך הוא מוכן למכירה בשלב הבא, מפרסם את הרכב למכירה במדיות השונות.',
      'Car dealer-label-4': 'קנייה של רכבים משומשים',
      'Car dealer-info-4': 'סוחרי הרכב קונים רכבים במכרזים, מגרשים של רכבי טרייד-אין ומאנשים פרטיים. הסוחרים מחזיקים בתו סחר שמאפשר להם לטפל ברכב עד מכירתו ללא צורך בהעברת בעלות וכך לא נרשמת על הרכב יד נוספת. בנוסף לכך, תו זה מאפשר להקפיא את מועד הטסט על מכירת הרכב והבעלים החדשים דואג לתשלום הטסט ולהעברת הרכב במבחן השנתי לגבי תקינותו.',
      'How it works-menu': 'איך זה עובד?',
      'How it works-title': 'איך זה בעצם עובד?',
      'How it works-subtitle': 'שלושה שלבים ויש לכם קורות חיים של רכב עם נתונים ותובנות לצאת לדרך',
      'How it works-label-1': 'חלק ראשון: רוורס 7 שנים אחורה',
      'How it works-info-1': 'מציידים אתכם במידע על הנקודות הרגישות ביותר בקניית רכב משומש',
      'How it works-info-1-checkmark-1': 'מעורבות בתאונות?',
      'How it works-info-1-checkmark-2': 'ירידת ערך עקב תאונות?',
      'How it works-info-1-checkmark-3': 'האם בעבר הרכב נגנב?',
      'How it works-info-1-checkmark-4': 'האם הרכב היה מבוטח?',
      'How it works-info-1-checkmark-5': '?האם הרכב הוכרז כטוטאל לוס',
      'How it works-info-1-checkmark-6': '?האם שולמו פיצויים מחברות הביטוח',
      'How it works-label-2': 'שלב שני: בעלויות קודמות',
      'How it works-info-2': 'מי שמוכר רכב יד שלישית אולי לא יודע הכל על העבר של הרכ',
      'How it works-info-2-checkmark-1': '?האם הרכב היה בבעלות גורם מסחרי בעבר',
      'How it works-info-2-checkmark-2': '?אולי יש סיבה לחשוד באחת הבעלויות הקודמות',
      'How it works-label-3': 'שלב שלישי: מסקנות וכלים לעסקה נכונה',
      'How it works-info-3': 'מציידים אתכם במידע על הנקודות הרגישות ביותר בקניית רכב משומש',
      'How it works-info-3-checkmark-1': 'האם ישנם נתונים בעייתיים שכדאי לשים לב אליהם?',
      'How it works-info-3-checkmark-2': 'האם דווקא הרכב הזה עושה רושם טוב?',
      'How it works-info-3-checkmark-3': '?מה הבינה המלאכותית שלנו אומרת על ההזדמנות',
      'How it works-info-4': 'מערכת הבינה המלאכותית מסייעת לכם עם תובנות, סיכומים ומסקנות, בעצם חושבת ביחד אתכם: מה הצעד שהכי נכון לעשות עכשיו',
      'How it works-info-4-checkmark-1': '?בכמה כדאי לקנות',
      'How it works-info-4-checkmark-2': '?ירידת ערך – מה אומרת התחזית שלנו',
      'How it works-info-4-checkmark-3': '?כמה יעלה לתחזק את הרכב הזה',
      'How it works-info-4-checkmark-4': 'טפסי מכירה',
      'How it works-info-4-checkmark-5': '?הנחיות וטיפים: כיצד יש לנהוג במכירה או קנייה',
      'Total loss-menu': 'טוטאל לוס',
      'Total loss-title': 'טוטאל לוס? איך לא קונים רכב כזה',
      'Total loss-subtitle': 'טוטאל לוס הוא מצב שבו רכב מורד מהכביש לאחר שהנזק שנגרם מתאונה שווה ל60% ומעלה מערכו. מה התהליך שמאחורי הכרזה על רכב כזה? כל המידע לפניכם',
      'Total loss-label-1': 'שמאי רכב – איש המקצוע בדיוק למצבים האלה',
      'Total loss-info-1': 'תו נכה הוא רישיון לחנות במקומות חניה השמוריםהלנכים בלבד – מוכר על ידי הגורמים הרשמיים של. מדינת ישראל בעל רכב שעמד בקריטריונים וקיבל ממשרד הרישוי, תעודה של נכה אשר מקלה על חיי היום יום שלו מקבל תג נכה שמודבק על שמשת הרכב שלו והדבר: מופיע ברשומות של משרד הרישוי. נכים וחולים',
      'Total loss-label-2': 'טוטאל לוס – מה זה?',
      'Total loss-info-2': 'טוטאל לוס הוא מצב בו אין כדאיות כלכלית לתקן את הרכב במצב זה, לכן השמאי מוריד אותו מהכביש. להוראה זו של השמאי יש כמה השלכות',
      'Total loss-info-2-checkmark-1': 'כיוון שהרכב במצב זה בדרך כלל אינו מסוגל לנוע בכבישים, גרר של מגרש הגרוטאות גורר אותו למגרש כדי לבצע פעולות מקיפות של פירוק. חלק מהחלפים מועברים למחזור וחלפים שהם תקינים מועברים למכירה כחלקים משומשים אך תקינים',
      'Total loss-info-2-checkmark-2': 'על הנהג לדווח למשרד הרישוי שהרכב פורק ולכן יש להוריד אותו מרישום הרכבים שנעים בכבישי הארץ',
      'Total loss-info-2-checkmark-3': 'חברת הביטוח מעבירה תשלום לבעל הרכב על פי ערכו המלא, בהתאם לקביעה של השמאי',
      'Total loss-label-3': 'אובדן חלקי של ערך הרכב',
      'Total loss-info-3': 'לאחר תאונה קלה יחסית, שמאי הרכב מאשר לאנשי המוסך לבצע תיקונים ספציפיים אשר ישולמו על ידי חברת הביטוח של הנהג הפוגע עם זאת התיקונים הללו יוצרים ירידה של אחוזים מסוימים בערך הרכב. לכל חלק של הרכב יש אחוז מסוים כמו לדוגמה',
      'Total loss-info-3-example-1': 'כנף אחורית - X%',
      'Total loss-info-3-example-2': 'קורת שלדה - Y%',
      'Total loss-info-3-example-3': 'פגיעה בפח - Z%',
      'Total loss-label-4': 'הביטוח תמיד בתמונה',
      'Total loss-info-4': 'חברת הביטוח משלמת לבעל הרכב את סך ירידת הערך כיוון שנפגע במהלך תאונה שלא הייתה באשמתו ובעת מכירת הרכב יקבל סכום נמוך יותר מהשווי של הרכב עקב כך',
      'Rules-menu': 'כללים',
      'Rules-title': 'כללים לקניית רכב יד שניה',
      'Rules-subtitle': 'מה חייבים לעשות לפני שקונים רכב משומש',
      'Rules-label-1': 'מדברים עם בעל הרכב / הנהג',
      'Rules-info-1': 'בשלב הזה חשוב מאוד לבקש תמונות עדכניות של הרכב ולשאול מהו הקילומטראז’, מה בעלויות קודמות, היסטורית תקלות וטיפולים',
      'Rules-label-2': 'לבדוק את הרכב ברוורס',
      'Rules-info-2': 'עם רוורס אפשר להכיר את הרכב שאתם שוקלים לקנות כבר מרחוק עוד לפני שטרחתם ונסעתם לבדוק את מצבו ולפגוש את בעליו',
      'Rules-label-3': 'לבדוק את הרכב ברוורס',
      'Rules-info-3': 'עם רוורס אפשר להכיר את הרכב שאתם שוקלים לקנות כבר מרחוק עוד לפני שטרחתם ונסעתם לבדוק את מצבו ולפגוש את בעליו',
      'Rules-label-4': 'נסיעת מבחן ובדיקה ויזואלית חיצונית',
      'Rules-info-4': 'בחנו את האוטו מבחוץ פתחו את מכסה המנוע לחפש חיבורים רופפים, סימני נזילות, תוקף מצבר, וקח את הרכב לנסיעת מבחן, חשוב מאוד לסגור את הרדיו לפתוח חלונות ולהקשיב אם יש רעשים חריגים, לבדוק שהמזגן תקין',
      'Rules-label-5': 'וידוא פרטים',
      'Rules-info-5': 'יש לוודא שהפרטים תואמים והבעלים הם אכן הבעלים לפי רישיון הרכב ותעודת הזהות של המוכר',
      'Rules-info-5-important-1': 'קראו את קורות החיים של הרכב ברוורס האם לרכב היו תאונות עם ירידת ערך ואת מספר הקילומטרים האחרון שדווח זה הזמן לשקול – עסקה הוגנת או לא',
      'Rules-info-5-important-2': 'חשוב מאוד!',
      'Rules-label-6': 'לא קנית? הדו”ח שלך שווה כסף',
      'Rules-info-6': 'במידה והרכב לא מתאים לצרכים שלך זה המקום להעלות את הדוח קניה לרכב (הרי גם ככה לא תשתמש בו)',
      'Rules-info-6-upload-info': 'העלאה של הדוח תקנה לך 25% הנחה לדוח רוורס הבא',
      'Rules-info-6-upload-button': 'ניתן להעלות את הקובץ כאן',
      'Rules-label-7': 'הסכמים? יש לנו כבר נוסח מוכן',
      'Rules-info-7-upload-info': 'מסמך גילוי נאות וטופס אחרי בדיקה/זיכרון דברים',
      'Rules-info-7-upload-button': 'מורידים מהאתר וחותמים',
      'Rules-label-8': 'יוצאים לדרך חדשה, שיהיה בהצלחה!',
      'Car number': 'Car number',
    }
  },
  ar: {
    translation: {
      'Home-Title': 'فحص المركبة قبل الشراء',
      'Home-Subtitle': 'حوادث , رهن , نقل ملكيات',
      'Home-Sale': 'الان فقط في سعر عرض 79.90 شاقل',
      'Home-Car number placeholder': 'ضع رقم المركبة',
      'Home-Check button': 'افحصوا',
      "Checking the seller vehicle": "فحص ملكيات",
      "Official mortgage check": "فحص رهونات",
      "Vehicle history from database": "السيرة الذاتية",
      "Impairment test and total loss": "فحص فقدان",
      "Maintenance costs and red flags": "قواعد لفحص",
      "Start with": "لنبدأ مع هذا",
      "Start with info": "لرجوع للخلف (ريفرس) يأخذك لماضي المركبة يجمع لك المعلومات ويمنح لكم السيرة الذاتية للمركبة بالضبط بالوقت الصحيح قبل شراء مركبة اليد الثانية هل مر بفقدان كلي إو ممكن أن تكون المركبة مسروقة أو محروقة؟ ما هو تاريخ ملكيات المركبة؟ من الممكن أن يكون شيء ما من ماضيه الذي يريدون اخفاءه. كل ما تريد معرفته- فقط خلال دقائق مباشرةً لبريدك الالكتروني .",
      "Check before seen": "لتفحص المركبة, قبل أن تراه ا",
      "Check before seen info": "تقوم منظوماتنا بجمع معلومات من كل مجامع المعلومات الرسمية . ما يتبقى لكم هو النقر والحصول خلال دقائق على النتيجة باللحظة القريبة من الشراء .",
      "Vehicle CV": "السيرة الذاتية للمركبة- ماذا تعني؟",
      "Vehicle CV info": "أسهل ما يكون: مستند بسيط, مقري وواضح يرسل مباشرةً للبريد الالكتروني . وتقوم بتفصيل المعطيات التي تعرفها السلطات عن المركبة المستعلمة والتي بودكم شرائها : حوادث , رهونات, انخفاض قيمة , تاريخ ملكيات , دعاوي تأمين – كل شي ء",
      "Check list": "ماذا تفحصون هنا؟",
      "Check list info": "رجوع خلفي للمالكين السابقين رجوع خلفي للرهونات رجوع خلفي لتاريخ المركب ة رجوع خلفي لانخفاض القيمة والفقدان الكلي رجوع خلفي للصيانة والتحذيرات واعادة الاتصالات",
      "Guide": "فيديو ارشاد",
      "How report looks": "Want to see what a check car report looks like",
      "Full name": "الاسم الكامل",
      "Full name require": "Please input your full name!",
      "Phone number": "الهاتف",
      "Phone number require": "Please input your phone number!",
      "Email address": "البريد الاكتروني",
      "Email address require": "Please input your email!",
      "Send": "ارس ل",
      "The commandments": "The commandments",
      "Report of stolen vehicle": "Report of stolen vehicle",
      "Report of a car seller": "Report of a car seller",
      "Accessibility": "Accessibility",
      "To cancel an order": "To cancel an order",
      "Terms": "Terms",
      "Privacy Policy": "Privacy Policy",
      "Contact us": "تواصلوا معنا بالرجوع الخلفي",
      "Drawer title": "Settings",
      "User logged out": "User logged out",
      "Log In": "Log In",
      "Logged as": "Logged as",
      "Logout": "Logout",
      "Login account": "Login account",
      "LOGIN": "LOGIN",
      "OR REGISTER": "OR REGISTER",
      "Something went wrong": "Something went wrong",
      "User logged in": "User logged in",
      "Email address is not valid": "Email address is not valid",
      "Password require": "Please input your password!",
      "Password": "Password",
      "Reset password": "Reset password",
      "Remember me": "Remember me",
      "Password conditions": "Min 8 chars, at least 1 letter and 1 number",
      "Confirm password": "Confirm password",
      "Confirm password require": "Please input your confirm password!",
      "Passwords do not match": "Passwords do not match",
      "SIGNUP": "SIGNUP",
      "RESET PASSWORD": "RESET PASSWORD",
      "BACK": "BACK",
      "Reset password - Alert description": "A request to reset your password has been sent to your email address. Please check your inbox to continue the process.",
      "Show report": "Show report",
      "Show reports history": "Show reports history",
      "Created date": "Created date",
      "File": "File",
      "Main page": "Main page",
      "Email sent successfully": "Email sent successfully",
      "Email sent description": "We have received your inquiry and we will contact you soon",
      "Owner ID": "ID number of the main vehicle owner",
      "Owner date": "Date of commencement of ownership of the vehicle (according to the vehicle license)",
      "Report preparation alert": "Thanks, your report is in preparation and may take several minutes. When done, you can view it on your reports page.",
      "Search another car": "Search another car",
      "Report demo": "Report demo",
      "Order report": "Order report",
      'Why-menu': 'למה?',
      'Why-title': 'למה חייבים להיכנס לרוורס?',
      'Why-subtitle': 'המידע שרוורס מעניקה לכם יכול לחסוך קניה מיותרת של רכב פגום. מכאנית או משפטית רוצים לנסוע בראש שקט? כנסו לרוורס',
      'Why-label-1': 'בדיקת רכב מעוקל',
      'Why-info-1': 'בעל חוב אשר עושה כל שניתן כדי לקבל את כספו פותח תיק בהוצאה לפועל ועקב כך נרשם עיקול במשרד התחבורה על הרכב בעל מספר הרישוי הספציפי. מי שמעוניין לבצע העברת בעלות ממוחשבת באחד מסניפי רשות הדואר, אך מסתבר, שהרכב מעוקל, לא יוכל לבצע העברת בעלות',
      'Why-label-2': 'איך פותרים את הבעיה?',
      'Why-info-2': 'אם הרוכש מעוניין ברכב זה, קיימות שתי אופציות לפתרון וזאת רק אם הבעלים הנוכחיים של הרכב, מכיר בחוב ומבין שיש לשלם אותו',
      'Why-info-2-checkmark-1': 'הקונה ישלם את החוב וכך יוסר השעבוד מהרכב. בשלב שני הוא ישלם למוכר הרכב רק את היתרה שנותרה ממחיר הרכב שעליו סוכם בקיזוז ערך החוב ששולם.',
      'Why-info-2-checkmark-2': 'אם המוכר ישלם בעצמו את החוב ורק לאחר שיוכיח שהשעבוד בוטל, אפשר יהיה לבצע העברת בעלות ממוחשבת.',
      'Why-label-3': 'רכב משועבד',
      'Why-info-3': 'בעל רכב שקיבל מימון לצורך רכישת הרכב מפעיל שעבוד על הרכב, כיוון שהרכב משמש בטוחה על החזר כל כספי ההלוואה. לפני שקונים, יש לוודא שאין שיעבוד על הרכב ואת זה. בדיווק רוורס עושה עבורכם',
      'Why-label-4': 'רכב ממושכן ברשם המשכונות',
      'Why-info-4': 'דרך נוספת להעביר בעלות,היא רישום של פרטי רכב ספציפי ברשם המשכונות וכך למנוע את. מכירתו לאדם אחר',
      'Why-label-5': 'הדרך הפשוטה להימנע מסיבוכים',
      'Why-info-5': 'בדיקה קטנה יכולה לחסוך לכם כאב ראש גדול נכנסים לרוורס ומגלים את כל מה שלא סיפרו. לכם על הרכב שאתם עומדים לקנות',
      'Handicapped-menu': 'תו נכה',
      'Handicapped-title': 'תו נכה: לשים לב לפני שקונים',
      'Handicapped-subtitle': 'אתם הרי ממש לא רוצים להגיע למצב שאין באפשרותכם להעביר בעלות, נכון? אז כדי למנוע, סיבוכים מיותרים, יש לדאוג לבטל את תו הנכה(במידה וקיים). בטרם מכירת הרכב הנה כל מה שצריך לדעת כדי לעשות את זה נכון',
      'Handicapped-label-1': 'מה הוא תו נכה?',
      'Handicapped-info-1': 'תו נכה הוא רישיון לחנות במקומות חניה השמוריםהלנכים בלבד – מוכר על ידי הגורמים הרשמיים של. מדינת ישראל בעל רכב שעמד בקריטריונים וקיבל ממשרד הרישוי, תעודה של נכה אשר מקלה על חיי היום יום שלו מקבל תג נכה שמודבק על שמשת הרכב שלו והדבר: מופיע ברשומות של משרד הרישוי. נכים וחולים',
      'Handicapped-label-2': 'קונים רכב? לא לשכוח לבדוק את נושא תו הנכה',
      'Handicapped-info-2': 'אחד הדברים שצריך לבדוק כל מי שרוכש רכב יד שנייה הוא אם לרכב זה משוויך תג נכה אשר מאפשר לו לחנות במקומות חנייה מיוחדים היות ומי שרוכב רכב יד שנייה וימשיך להחזיק (אפילו לא ביודעין)',
      'Handicapped-label-3': 'אז מה עושים במשרד הרישוי?',
      'Handicapped-info-3': 'לאור ההטבות וההקלות בנושא חנייה עבור הנכים כאשר מוכרים רכב הנושא תו, משרד התחבורה לא מאפשר להעביר זכות זו של תג נכה לבעלים החדשים של הרכב כיוון שהם אינם מוכרים כנכים על ידי משרד הרישוי לכן, לפני ביצוע העברת הבעלות על בעל הרכב הנוכחי להחזיר למשרד הרישוי את תג הנכה, כדי שהסטטוס של מספר רישוי זה יהיה כמו כל רכב נורמטיבי ולא בעל זכאות ייחודית. רק לאחר. שמבצעים מהלך זה אפשר לבצע העברת בעלות',
      'Handicapped-label-4': 'קונים רכב? תבדקו מראש את נושא התו נכה',
      'Handicapped-info-4': 'מי שקונה רכב ואינו יודע שיש לו תו נכה, כשירצה לבצע העברת בעלות הוא ייתקל בסירוב, זאת, בשל תג הנכה. במקרים האלו הוא יבקש מבעל הרכב לבצע את המהלך הנדרש ורק לאחר מכן יוכל. לבצע את השינוי הנדרש',
      'Curved vehicle-menu': 'רכב מעוקל או משועבד',
      'Curved vehicle-title': 'רכב מעוקל או משועבד איך נמנעים מקנייה?',
      'Curved vehicle-subtitle': 'מה עושים כדי לא להגיע למצב שאתם עומדים לקנות רכב ואז מגלים שבעצם הבעלים הם לא לגמרי הבעלים? ניחשתם נכון: נכנסים לרוורס',
      'Curved vehicle-label-1': 'הצד המשפטי של הרכב הבא שלכם',
      'Curved vehicle-info-1': 'אדם שמעוניין לרכוש רכב יד שנייה, חייב לבדוק את מצבו של הרכב מבחינה מכנית, חשמלית וגם את הפאן המשפטי. יש מקרים שבהם הרכב מעוקל או משועבד ולכן יש להיות ערניים כדי לא להיקלע לצרה.',
      'Curved vehicle-label-2': 'רוורס פותרת את הבעיה',
      'Curved vehicle-info-2': 'המידע שמקבלים מקורות החיים של הרכב מרוורס מאפשר לדעת האם הרכב מעוקל או משועבד וכמו כן הרבה דברים אחרים שחשוב לבדוק ולדעת.',
      'Curved vehicle-label-3': 'העברת בעלות של הרכב',
      'Curved vehicle-info-3': 'מצאת רכב שנראה לך מתאים לדרישותיך? מצויין. עכשיו, כדאי לבדוק שגם המצב המשפטי שלו ראוי לעסקה',
      'Curved vehicle-label-4': 'שעבוד של רכב',
      'Curved vehicle-info-4': 'אדם שחייב כספים לגורמים שונים כמו ספקים, רשויות המס, או כל גורם אחר וקיים חשש שהוא לא יעמוד בהתחייבויות הכספיות שלו, בעל החוב פותח לו תיק בהוצאה לפועל. בדרך זו הוא מעקל כספים, נכסים ורכבים אשר קיימים ברשותו של אדם זה. המטרה היא לממש את הנכסים הללו, או חלק מהם כדי לקבל בדרך זו את התשלום אשר מגיע לגורם זה.',
      'Curved vehicle-label-5': 'משכון של רכב',
      'Curved vehicle-info-5': 'יש גורמים שמבצעים משכון לרכב כאן ברוורס, ניתן לדעת אם יש משכון ולמי',
      'Recall-menu': 'צו ריקול',
      'Recall-title': 'צו ריקול? יש חלק פסול!',
      'Recall-subtitle': 'ריקול הוא מצב שבו יצרנית רכב מכריזה על כך שיש חלק עם פגם בייצור ויש להחליפו בכל הרכבים. כשקונים רכב משומש יש לשים לב שהרכב שאתם קונים ביצע ריקול במידה והיה צריך',
      'Recall-label-1': 'כשיצרנית מלה פגם בייצור והרכבים כבר על הכביש',
      'Recall-info-1': 'יצרני הרכב בכל העולם מקדישים מחשבה רבה לכל נושא הבטיחות ברכב ולכן הם בוחנים את כל תהליכי התיקון והבדיקה, אשר קור מהרגע שהרכב נמכר וזאת כיוון שהרכבים מגיעים לטיפולים ולבדיקות שוטפות במוסכים בהתאם להוראות של יצרן הרכב.',
      'Recall-label-2': 'קריאת ריקול לרכבים',
      'Recall-info-2': 'אם קיימת תקלה שהיא חד פעמית או תקלה שחוזרת על עצמה שוב ושוב, מהנדסי הרכב בודקים זאת באופן מעמיק ולא אחת הם מגיעים למסקנה שיש בעיה טכנית בחלקים מסוימים ולכן יש להחליפם כדי להגן על הנהגים והנוסעים ולהעניק להם בטיחות מלאה, יצרני הרכב מייצרים את החלף החדש שיהיה מתאים יותר מהחלק המקורי. השלב הבא הוא קריאה לכל בעלי הרכב בדגם הספציפי על פי שנת הייצור להגיע לאחד המוסמכים המורשים כדי לבצע את ההחלפה. כל מי שרכש רכב מסוג זה, מקבל על כך הודעה באחד מאמצעי התקשורת ובהתאם לכך הוא מתאם תור למוסך מורשה כדי לבצע את ההחלפה בפועל.',
      'Recall-label-3': 'מחויבות לביצוע ריקול בפועל',
      'Recall-info-3': 'לאור החשיבות הרבה של ביצוע הריקול, כדי למנוע מצבים שהם לא בטיחותיים, כל בעל רכב מחויב לבצע פעולה זו מוקדם ככל האפשר',
      'Recall-label-4': 'ריקול, תנאי הכרחי כדי לעבור את הטסט',
      'Recall-info-4': 'בכל שנה כל רכב עובר מבחן רישוי שנתי כדי לבדוק את תקינותו. בעל רכב שמגיע למכון הרישוי ועל פי הבדיקה יסתבר שהוא לא פעל כנדרש ולא בוצעה החלפה של החלק הספציפי על פי הוראת היצרן, לא יוכל לעבור את מבחן הטסט השנתי רק לאחר שהוא יבצע את הפעולה הנדרשת, הוא יגיע שוב למכון הרישוי ואם הכול תקין, הוא יעבור את הטסט ויוכל להמשיך לנסוע ברכב',
      'Certified garage-menu': 'מוסך מורשה',
      'Certified garage-title': 'מוסך מורשה: מדוע הוא הכרחי?',
      'Certified garage-subtitle': 'משרד התחבורה מעניק רישיון ופיקוח בשבילנו ציבור הנהגים שרוצה לדעת שהרכב שלנו בידיים טובות',
      'Certified garage-label-1': 'מה זה מוסך?',
      'Certified garage-info-1': 'מוסכים מורשים מטעם משרד התחבורה הם מוסכים שנמצאים בפיקוח של משרד התחבורה והם בדרך כלל חברים באיגוד המוסכים',
      'Certified garage-label-2': 'מה הסטנדרט אליו מחויבים המוסכים המורשים?',
      'Certified garage-info-2': 'במוסך מורשה משרד התחבורה מעניקים טיפול מקצועי לרכבים מכל הסוגים ושל כל יצרני הרכב אשר הרכבים המיובאים ארצה. המוסכים הללו מחויבים לפעול על פי תקנות מחמירות. עובדי מוסך עוברים הכשרות והדרכות מקצועיות בכל הקשור לאופן הבדיקה, התיקון ותחזוקה של כל אחד מהרכבים של היצרנים השונים. בראש המוסך מחויב לעמוד מכונאי בעל הכשרות ספציפיות, המנהל את המערך המקצועי',
      'Certified garage-label-3': 'שקיפות: ככה עובד מוסך מורשה',
      'Certified garage-info-3': 'במוסך מסוג זה חייב להיות תלוי מחירון במקום גלוי לעין. שם מפורטות העלויות של שעת העבודה, ושל העלות של החלפים המקוריים. עובדי המוסך שמקבלים רכב לטיפול, מחויבים לאחר הבדיקה ליידע את הלקוח מהם הממצאים שלהם, מה יש לתקן ומהן העלויות של התיקון, החלפים ושעות העבודה. רק לאחר אישורו של הלקוח לביצוע העבודה, צוות העובדים מבצע זאת בפועל',
      'Certified garage-label-4': 'תיעוד של הפעולות במוסך מורשה',
      'Certified garage-info-4': 'במוסך מורשה, כל הנתונים על התיקונים השונים שבוצעו ברכב, אילו חלפים הוחלפו, מתי בוצעו הטיפולים השוטפים וכל מידע אחר, מפורטים בתוכנה הממוחשבת. כך אפשר לבדוק לדוגמה מתי הוחלפו הבלמים, רצועת הטיימינג וכו’. מידע זה חשוב לכל בעל רכב ובמיוחד בעת רכישת רכב יד שנייה וכך הוא רואה את מלוא האינפורמציה באופן מרוכז',
      'Certified garage-label-5': 'מוסכי מדרכה – איך מזהים ונזהרים?',
      'Certified garage-info-5': 'מוסכי מדרכה הם מוסכים פיראטיים אשר פועלים ללא פיקוח. לכן לא אחת הנהגים לא מרוצים מהטיפולים שבוצעו ברכב כיוון שאין למי לפנות במקרה של בעיה ואין עליהם פיקוח של משרד התחבורה. המחירים במוסכים הללו הם גבוהים ולא תמיד מותקנים ברכבים המגיעים לתיקון חלפים מקוריים או חדשים. יש חלפים משומשים שמותקנים ברכבים שהם מחזיקים מעמד פרק זמן קצר, או שהם לא תקינים לאור כל זאת ההמלצה היא לא לתקן את הרכבים במוסכי מדרכה',
      'Car dealer-menu': 'סוחר רכב מורשה',
      'Car dealer-title': 'סוחר רכב מורשה: למה הוא חשוב בעצם?',
      'Car dealer-subtitle': 'רוצים לקנות רכב בלי להסתכן בעסקה מפוקפקת? קונים מסוחר רכב מורשה המחזיק ברישיון ממשרד התחבורה ונוסעים בראש שקט',
      'Car dealer-label-1': 'מהו סוחר רכב מורשה?',
      'Car dealer-info-1': 'סוחר רכב מורשה הוא בעל מקצוע שקיבל אישור על כך שהעסק שלו בתחום המסחר במכוניות הוא מפוקח ולכן הוא מאושר לתפעול על ידי משרד התחבורה.',
      'Car dealer-label-2': 'היתרונות לקניה מסוחר רכב',
      'Car dealer-info-2-checkmark-1': 'קנייה ממקום עם אחריות',
      'Car dealer-info-2-checkmark-2': 'קנייה ממקום אשר דואג למוניטין שלו',
      'Car dealer-info-2-checkmark-3': 'קנייה מבעל מקצוע אשר אמון לדעת ולהבין את מאפייני הרכב ואחראי עליהם',
      'Car dealer-info-2-checkmark-4': 'קבלת רכב לאחר השבחה',
      'Car dealer-label-3': 'מטפלים ברכב ומוכרים אותו',
      'Car dealer-info-3': 'המגמה של כל אחד מסוחרי הרכב היא לרכוש רכבים שונים בעלות שהיא נמוכה ממחיר המחירון ולאחר מכן הם מעבירים את הרכב לטיפול של אנשי מקצוע שהם עובדים איתם בשיתוף פעולה כמו חשמלאי רכב, מכונאי רכב, איש פחחות, איש צבע ועוד בהתאם למצבו שלה הרכב הוא מזמין מהם את ביצוע הטיפולים הנדרשים כדי שאפשר יהיה למכור רכב תקין, כמו לדוגמה צבע ותיקוני פחחות. לאחר מכן כל רכב עובר ניקיון יסודי פנימי וחיצוני וכך הוא מוכן למכירה בשלב הבא, מפרסם את הרכב למכירה במדיות השונות.',
      'Car dealer-label-4': 'קנייה של רכבים משומשים',
      'Car dealer-info-4': 'סוחרי הרכב קונים רכבים במכרזים, מגרשים של רכבי טרייד-אין ומאנשים פרטיים. הסוחרים מחזיקים בתו סחר שמאפשר להם לטפל ברכב עד מכירתו ללא צורך בהעברת בעלות וכך לא נרשמת על הרכב יד נוספת. בנוסף לכך, תו זה מאפשר להקפיא את מועד הטסט על מכירת הרכב והבעלים החדשים דואג לתשלום הטסט ולהעברת הרכב במבחן השנתי לגבי תקינותו.',
      'How it works-menu': 'איך זה עובד?',
      'How it works-title': 'איך זה בעצם עובד?',
      'How it works-subtitle': 'שלושה שלבים ויש לכם קורות חיים של רכב עם נתונים ותובנות לצאת לדרך',
      'How it works-label-1': 'חלק ראשון: רוורס 7 שנים אחורה',
      'How it works-info-1': 'מציידים אתכם במידע על הנקודות הרגישות ביותר בקניית רכב משומש',
      'How it works-info-1-checkmark-1': 'מעורבות בתאונות?',
      'How it works-info-1-checkmark-2': 'ירידת ערך עקב תאונות?',
      'How it works-info-1-checkmark-3': 'האם בעבר הרכב נגנב?',
      'How it works-info-1-checkmark-4': 'האם הרכב היה מבוטח?',
      'How it works-info-1-checkmark-5': '?האם הרכב הוכרז כטוטאל לוס',
      'How it works-info-1-checkmark-6': '?האם שולמו פיצויים מחברות הביטוח',
      'How it works-label-2': 'שלב שני: בעלויות קודמות',
      'How it works-info-2': 'מי שמוכר רכב יד שלישית אולי לא יודע הכל על העבר של הרכ',
      'How it works-info-2-checkmark-1': '?האם הרכב היה בבעלות גורם מסחרי בעבר',
      'How it works-info-2-checkmark-2': '?אולי יש סיבה לחשוד באחת הבעלויות הקודמות',
      'How it works-label-3': 'שלב שלישי: מסקנות וכלים לעסקה נכונה',
      'How it works-info-3': 'מציידים אתכם במידע על הנקודות הרגישות ביותר בקניית רכב משומש',
      'How it works-info-3-checkmark-1': 'האם ישנם נתונים בעייתיים שכדאי לשים לב אליהם?',
      'How it works-info-3-checkmark-2': 'האם דווקא הרכב הזה עושה רושם טוב?',
      'How it works-info-3-checkmark-3': '?מה הבינה המלאכותית שלנו אומרת על ההזדמנות',
      'How it works-info-4': 'מערכת הבינה המלאכותית מסייעת לכם עם תובנות, סיכומים ומסקנות, בעצם חושבת ביחד אתכם: מה הצעד שהכי נכון לעשות עכשיו',
      'How it works-info-4-checkmark-1': '?בכמה כדאי לקנות',
      'How it works-info-4-checkmark-2': '?ירידת ערך – מה אומרת התחזית שלנו',
      'How it works-info-4-checkmark-3': '?כמה יעלה לתחזק את הרכב הזה',
      'How it works-info-4-checkmark-4': 'טפסי מכירה',
      'How it works-info-4-checkmark-5': '?הנחיות וטיפים: כיצד יש לנהוג במכירה או קנייה',
      'Total loss-menu': 'טוטאל לוס',
      'Total loss-title': 'טוטאל לוס? איך לא קונים רכב כזה',
      'Total loss-subtitle': 'טוטאל לוס הוא מצב שבו רכב מורד מהכביש לאחר שהנזק שנגרם מתאונה שווה ל60% ומעלה מערכו. מה התהליך שמאחורי הכרזה על רכב כזה? כל המידע לפניכם',
      'Total loss-label-1': 'שמאי רכב – איש המקצוע בדיוק למצבים האלה',
      'Total loss-info-1': 'תו נכה הוא רישיון לחנות במקומות חניה השמוריםהלנכים בלבד – מוכר על ידי הגורמים הרשמיים של. מדינת ישראל בעל רכב שעמד בקריטריונים וקיבל ממשרד הרישוי, תעודה של נכה אשר מקלה על חיי היום יום שלו מקבל תג נכה שמודבק על שמשת הרכב שלו והדבר: מופיע ברשומות של משרד הרישוי. נכים וחולים',
      'Total loss-label-2': 'טוטאל לוס – מה זה?',
      'Total loss-info-2': 'טוטאל לוס הוא מצב בו אין כדאיות כלכלית לתקן את הרכב במצב זה, לכן השמאי מוריד אותו מהכביש. להוראה זו של השמאי יש כמה השלכות',
      'Total loss-info-2-checkmark-1': 'כיוון שהרכב במצב זה בדרך כלל אינו מסוגל לנוע בכבישים, גרר של מגרש הגרוטאות גורר אותו למגרש כדי לבצע פעולות מקיפות של פירוק. חלק מהחלפים מועברים למחזור וחלפים שהם תקינים מועברים למכירה כחלקים משומשים אך תקינים',
      'Total loss-info-2-checkmark-2': 'על הנהג לדווח למשרד הרישוי שהרכב פורק ולכן יש להוריד אותו מרישום הרכבים שנעים בכבישי הארץ',
      'Total loss-info-2-checkmark-3': 'חברת הביטוח מעבירה תשלום לבעל הרכב על פי ערכו המלא, בהתאם לקביעה של השמאי',
      'Total loss-label-3': 'אובדן חלקי של ערך הרכב',
      'Total loss-info-3': 'לאחר תאונה קלה יחסית, שמאי הרכב מאשר לאנשי המוסך לבצע תיקונים ספציפיים אשר ישולמו על ידי חברת הביטוח של הנהג הפוגע עם זאת התיקונים הללו יוצרים ירידה של אחוזים מסוימים בערך הרכב. לכל חלק של הרכב יש אחוז מסוים כמו לדוגמה',
      'Total loss-info-3-example-1': 'כנף אחורית - X%',
      'Total loss-info-3-example-2': 'קורת שלדה - Y%',
      'Total loss-info-3-example-3': 'פגיעה בפח - Z%',
      'Total loss-label-4': 'הביטוח תמיד בתמונה',
      'Total loss-info-4': 'חברת הביטוח משלמת לבעל הרכב את סך ירידת הערך כיוון שנפגע במהלך תאונה שלא הייתה באשמתו ובעת מכירת הרכב יקבל סכום נמוך יותר מהשווי של הרכב עקב כך',
      'Rules-menu': 'כללים',
      'Rules-title': 'כללים לקניית רכב יד שניה',
      'Rules-subtitle': 'מה חייבים לעשות לפני שקונים רכב משומש',
      'Rules-label-1': 'מדברים עם בעל הרכב / הנהג',
      'Rules-info-1': 'בשלב הזה חשוב מאוד לבקש תמונות עדכניות של הרכב ולשאול מהו הקילומטראז’, מה בעלויות קודמות, היסטורית תקלות וטיפולים',
      'Rules-label-2': 'לבדוק את הרכב ברוורס',
      'Rules-info-2': 'עם רוורס אפשר להכיר את הרכב שאתם שוקלים לקנות כבר מרחוק עוד לפני שטרחתם ונסעתם לבדוק את מצבו ולפגוש את בעליו',
      'Rules-label-3': 'לבדוק את הרכב ברוורס',
      'Rules-info-3': 'עם רוורס אפשר להכיר את הרכב שאתם שוקלים לקנות כבר מרחוק עוד לפני שטרחתם ונסעתם לבדוק את מצבו ולפגוש את בעליו',
      'Rules-label-4': 'נסיעת מבחן ובדיקה ויזואלית חיצונית',
      'Rules-info-4': 'בחנו את האוטו מבחוץ פתחו את מכסה המנוע לחפש חיבורים רופפים, סימני נזילות, תוקף מצבר, וקח את הרכב לנסיעת מבחן, חשוב מאוד לסגור את הרדיו לפתוח חלונות ולהקשיב אם יש רעשים חריגים, לבדוק שהמזגן תקין',
      'Rules-label-5': 'וידוא פרטים',
      'Rules-info-5': 'יש לוודא שהפרטים תואמים והבעלים הם אכן הבעלים לפי רישיון הרכב ותעודת הזהות של המוכר',
      'Rules-info-5-important-1': 'קראו את קורות החיים של הרכב ברוורס האם לרכב היו תאונות עם ירידת ערך ואת מספר הקילומטרים האחרון שדווח זה הזמן לשקול – עסקה הוגנת או לא',
      'Rules-info-5-important-2': 'חשוב מאוד!',
      'Rules-label-6': 'לא קנית? הדו”ח שלך שווה כסף',
      'Rules-info-6': 'במידה והרכב לא מתאים לצרכים שלך זה המקום להעלות את הדוח קניה לרכב (הרי גם ככה לא תשתמש בו)',
      'Rules-info-6-upload-info': 'העלאה של הדוח תקנה לך 25% הנחה לדוח רוורס הבא',
      'Rules-info-6-upload-button': 'ניתן להעלות את הקובץ כאן',
      'Rules-label-7': 'הסכמים? יש לנו כבר נוסח מוכן',
      'Rules-info-7-upload-info': 'מסמך גילוי נאות וטופס אחרי בדיקה/זיכרון דברים',
      'Rules-info-7-upload-button': 'מורידים מהאתר וחותמים',
      'Rules-label-8': 'יוצאים לדרך חדשה, שיהיה בהצלחה!',
      'Car number': 'Car number',
    }
  },
  fr: {
    translation: {
    }
  }
};

export default translations;
