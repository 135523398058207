import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Divider, Drawer, Button, Input} from 'antd';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {User} from './Authentication';
import {testBot, testBot2} from '../services/govApi';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Language = styled.p`
  font-size: 12px;
  font-weight: ${({selected}) => (selected && 'bold')};
  color: #1890ff;
  cursor: pointer;
`

const languages = [
  {lng: 'en', title: 'English'},
  {lng: 'he', title: 'עברית'},
  {lng: 'ru', title: 'Русский'},
  {lng: 'ar', title: 'عربيه'},
]

const DrawerSettings = ({visible, onClose, language, changeLanguage}) => {
  const {t, i18n} = useTranslation();
  const [url, setUrl] = useState('');
  console.log(url)
  return (
      <Drawer
          title={t('Drawer title')}
          width={250}
          placement={i18n.dir() === 'rtl' ? 'right' : 'left'}
          onClose={onClose}
          visible={visible}
      >
        <Column>
          <User />
          <Divider />
          <Link to="/" onClick={onClose}>{t('Main page')}</Link>
          <Divider />
          <Link to="/why" onClick={onClose}>{t('Why-menu')}</Link>
          <Divider />
          <Link to="/handicapped" onClick={onClose}>{t('Handicapped-menu')}</Link>
          <Divider />
          <Link to="/curved-vehicle" onClick={onClose}>{t('Curved vehicle-menu')}</Link>
          <Divider />
          <Link to="/recall" onClick={onClose}>{t('Recall-menu')}</Link>
          <Divider />
          <Link to="/certified-garage" onClick={onClose}>{t('Certified garage-menu')}</Link>
          <Divider />
          <Link to="/car-dealer" onClick={onClose}>{t('Car dealer-menu')}</Link>
          <Divider />
          <Link to="/how-it-works" onClick={onClose}>{t('How it works-menu')}</Link>
          <Divider />
          <Link to="/total-loss" onClick={onClose}>{t('Total loss-menu')}</Link>
          <Divider />
          <Link to="/rules" onClick={onClose}>{t('Rules-menu')}</Link>
          <Divider />
          <Link to="/reports-history" onClick={onClose}>{t('Show reports history')}</Link>
          <Divider />
          <h4>Choose Language</h4>
          {languages.map(({lng, title}) => (
              <Language key={lng} onClick={() => changeLanguage(lng)} selected={language === lng}>{title}</Language>
          ))}
          <Divider />
          <Button onClick={testBot}>Test Bot</Button>
          <Input value={url} onChange={({target: {value}}) => setUrl(value)} />
          <Button onClick={() => testBot2(url)}>Test Bot 2</Button>
          <Divider />
        </Column>
      </Drawer>
  )
}

export default DrawerSettings;
